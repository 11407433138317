import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/service/user.service';
import { ChildCareProvider } from '../../models/child-care-provider';
import { CapacityRequestService } from '../../service/capacity-request.service';

@Component({
  selector: 'app-set-availability',
  templateUrl: './set-availability.component.html',
  styleUrls: ['./set-availability.component.css']
})
export class SetAvailabilityComponent {  
  password: string;
  confirmPass: string;
  message: string;
  token: string;
  valid = true;
  complete = false;
  activeLang = 'en';
  childCare: ChildCareProvider;

  constructor(private route: ActivatedRoute, private userService: UserService, private toastr: ToastrService, private childCareService: CapacityRequestService) {

    this.childCare = new ChildCareProvider();
    this.token = this.route.snapshot.paramMap.get("token");

    //call availability service to validate Token
    this.userService.validateAvailabilityToken(this.token).subscribe(res => {

      //if (res.result === "success") {
      if (!res.id) {
        this.valid = false;
        return;
      }
      if (res) {
        this.childCare = res;
       // console.log(this.childCare);
        this.valid = true;
      }
   

    });
    
  }


  updateServiceCall(sectionId: number) {

    this.childCareService.updateAvailability(this.childCare, this.childCare.userId, sectionId).subscribe(result => {
     // this.childCare = result;
      
      if (this.activeLang === "en") {
        this.toastr.success("Changes Saved Successfully");
      }
      else {
        this.toastr.success("Cambios guardados Correctamente");
      }
    });
  }

  updateAvailability(event: any) {
    if (this.childCare.isThereCapacity) {
      this.childCare.isThereCapacity = !this.childCare.isThereCapacity;
      this.childCare.infantAvailability = 0;
      this.childCare.toddlerAvailability = 0;
      this.childCare.preschoolAvailability = 0;
      this.childCare.schoolAgeAvailability = 0;
    }
    else {
      if (this.childCare.infantAvailability === 0 &&
        this.childCare.toddlerAvailability === 0 &&
        this.childCare.preschoolAvailability === 0 &&
        this.childCare.schoolAgeAvailability === 0) {
        //Replace for multilanguage
        this.toastr.warning("Please indicate the number of open spots by age group");
        event.target.checked = false;
        return;
      }
      else
        this.childCare.isThereCapacity = !this.childCare.isThereCapacity;
    }

    this.updateServiceCall(1);
  }


  updateAvailabilityLastUpdated(event: any) {
 
    this.childCareService.updateAvailabilityLastUpdated(this.childCare).subscribe(result => {
      // this.childCare = result;

      if (this.activeLang === "en") {
        this.toastr.success("Changes Saved Successfully");
      }
      else {
        this.toastr.success("Cambios guardados Correctamente");
      }
    });

  }
  //deprecated
  updateAvailabilityIndicators() {
    this.updateServiceCall(2);
  }
  //deprecated
  updateCapacityIndicators() {
    // console.log(this.childCare);
    this.childCare.infantCapacity = this.childCare.infantCapacity ?? 0;
    this.childCare.toddlerCapacity = this.childCare.toddlerCapacity ?? 0;
    this.childCare.preschoolCapacity = this.childCare.preschoolCapacity ?? 0;
    this.childCare.schoolAgeCapacity = this.childCare.schoolAgeCapacity ?? 0;
    this.updateServiceCall(13);
  }

  MaxCapacity(i: number) {
    return new Array(i);
  }

  updateAvailabilityAndCapacitySingleButton() {
    // console.log(this.childCare);

    //next infant slot available
    //if (!this.childCare?.aditionalFilters?.nextInfantSlotAvailable)
    //  this.childCare.aditionalFilters.nextInfantSlotAvailable = null;

    //availability
    //this.childCare.infantAvailability = this.childCare.infantAvailability ?? 0;
    //this.childCare.toddlerAvailability = this.childCare.toddlerAvailability ?? 0;
    //this.childCare.preschoolAvailability = this.childCare.preschoolAvailability ?? 0;
    //this.childCare.schoolAgeAvailability = this.childCare.schoolAgeAvailability ?? 0;
    //capacity
    this.childCare.infantCapacity = this.childCare.infantCapacity ?? 0;
    this.childCare.toddlerCapacity = this.childCare.toddlerCapacity ?? 0;
    this.childCare.preschoolCapacity = this.childCare.preschoolCapacity ?? 0;
    this.childCare.schoolAgeCapacity = this.childCare.schoolAgeCapacity ?? 0;


    //turn toggle ON if any slot is >0
    if (this.childCare.infantAvailability > 0 || this.childCare.toddlerAvailability > 0 || this.childCare.preschoolAvailability > 0 || this.childCare.schoolAgeAvailability > 0)
      this.childCare.isThereCapacity = true;
    //turn toggle off if all slots were set to  zero
    if (this.childCare.infantAvailability === 0 && this.childCare.toddlerAvailability === 0 && this.childCare.preschoolAvailability === 0 && this.childCare.schoolAgeAvailability === 0)
      this.childCare.isThereCapacity = false;

    this.updateServiceCall(13);
  }

}
