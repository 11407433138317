import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent {
  license: string;
  showDialog= false;
  registerSended = false;
  message: string;
  securedEmail: string;
  automaticRedirect = "0";
  errorMessage = false;
  activeLang = 'en';

  constructor(private route: ActivatedRoute, private userService: UserService, private toastr: ToastrService) {
    this.automaticRedirect = this.route.snapshot.paramMap.get("auto");
    if (localStorage.getItem("activeLang"))
      this.activeLang = localStorage.getItem("activeLang");
  }

  resetRequest(): void {

    if (!this.isEmailValid(this.license)) {
      this.toastr.error('Invalid Email', 'Wrong email');
      return;
    }


    this.userService.resetPasswordRequest(this.license).subscribe(res => {
      if(res.result === "success")
      {
        this.showDialog = true;
        this.registerSended = true;
        this.securedEmail = res.email;
      }
      if(res.result === "user_error")
      {
        this.showDialog = true;
        this.errorMessage = true;
      }
      if (res.result === "error") {
        if (this.activeLang == 'en')
          this.message = res.message;
        else
          this.message = "Hubo un error al actualizar el password ";
      }
    });
  }

  updateLicense(event: string) {
    this.license = event;
  }

  isEmailValid(email: string): boolean {

    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return expression.test(email);   
  }
}
