import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {
  license: string;
  showDialog= false;
  registerSended = false;
  message = "";
  securedEmail: string;
  loading = false;
  confirmEmail = false;
  licenseNumber: string;
  childCareName: string;
  childCareEmail: string;

  constructor(private userService: UserService) { }

  registerRequest(): void {
    this.loading = true
    if (!this.license || this.license.trim().length === 0) {
      this.loading = false;
      this.message = "License number required.";
     
    }
    
    this.userService.registerUserRequest(this.license).subscribe(res => {
      if(res.result === "success")
      {
        this.loading = false;
        this.showDialog = true;
        this.registerSended = true;
        this.securedEmail = res.email;
      }
      if (res.result === "no_email") {
        this.loading = false;
        this.confirmEmail = true;
        this.childCareName = res.name;
      }
      if (res.result === "error") {
        this.loading = false;
        this.message = res.message;
      }
    });
  }

  registerNewEmailRequest(): void {
    this.loading = true
    if (!this.validateEmail(this.childCareEmail)) {
      this.loading = false;
      this.message = "Please enter a valid email address.";
      return;
    }

    this.userService.registerNewEmailRequest(this.license, this.childCareEmail).subscribe(res => {
      if(res.result === "success")
      {
        this.loading = false;
        this.showDialog = true;
        this.registerSended = true;
        this.securedEmail = res.email;
      }
      if (res.result === "error") {
        this.loading = false;
        this.message = res.message;
      }
      else {this.message = "An error ocurred registering the user"; this.loading = false;}
    });
  }


  updateLicense(event: string) {
    this.license = event;
      
    if (this.license)
      this.message = "";
  }

  updateEmail(event: string) {
    this.childCareEmail = event;

    if (this.childCareEmail)
      this.message = "";
  }

  validateEmail(email) {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return (true)
    }
    //alert("You have entered an invalid email address!")
    return (false)
  }

}
