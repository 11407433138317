import { Component} from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../service/admin.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService } from '../../auth/authentication.service';
import { ChildCareProvider } from '../../models/child-care-provider';

@Component({
  selector: 'app-additional-user',
  templateUrl: './additional-user.component.html',
  styleUrls: ['./additional-user.component.css']
})
export class AdditionalUserComponent {

  providerId: number;
  userId: number;
  primaryEmailMgmt: any[];
  newEmailInput: string;
  dhhsEmailselected: string;
  originalEmail: string;
  editPrimaryEmail: string;
  userResult: any[];
  isClickedNext: boolean = false;
  isCheckedUpdatedDisplayEmail: boolean = false;
  isChecked: boolean = true;
  additionalEmail: string;
  childCare: ChildCareProvider;
    
  constructor(private route: ActivatedRoute, private adminService: AdminService, private toastr: ToastrService, private authService: AuthenticationService) {


    this.authService.getCurrentChildCareObservable().subscribe(result => {

      this.childCare = this.authService.getCurrentChildCare();
      if (this.childCare) {

        this.providerId = this.childCare.id; //parseInt(localStorage.getItem("currentProvider"));


        if (this.providerId) {
          this.adminService.getPrimaryMgmtInfo(this.providerId).subscribe(response => {
           // console.log(response);
            this.primaryEmailMgmt = response;
          });
        }

      }

    });

    /*
    this.childCare = this.authService.getCurrentChildCare();
    //this.providerId = parseInt(this.route.snapshot.paramMap.get("providerid"));
    this.providerId = this.childCare.id; //parseInt(localStorage.getItem("currentProvider"));


    if (this.providerId) {
      this.adminService.getPrimaryMgmtInfo(this.providerId).subscribe(response => {
        console.log(response);
        this.primaryEmailMgmt = response;
      });
    }*/
    

  }


  //ngOnChanges(): void {
  //  this.childCare = this.authService.getCurrentChildCare();
  //  //this.providerId = parseInt(this.route.snapshot.paramMap.get("providerid"));
  //  this.providerId = this.childCare.id; //parseInt(localStorage.getItem("currentProvider"));


  //  if (this.providerId) {
  //    this.adminService.getPrimaryMgmtInfo(this.providerId).subscribe(response => {
  //      console.log(response);
  //      this.primaryEmailMgmt = response;
  //    });
  //  }

  //}


  createAdditionalUser(userType: number) {



    if (!this.isEmailValid(this.additionalEmail)) {
      this.toastr.error('Invalid Email', 'Error creating additional user');
      return;
    }

    this.adminService.createUser(this.providerId, this.additionalEmail, false, userType).subscribe(response => {
      console.log("additional:", response);

      if (response.success)
        this.toastr.success(response.message);
      else
        this.toastr.error(response.message);

      this.reloadContent(this.providerId);
      //colllapse
      this.addAdditionalUser();


    });
  }

  deleteUser(item: any) {

    this.adminService.deleteUser(item.userId, item.providerId, item.username).subscribe(response => {
      console.log("deleted:", response);
      if (response.success)
        this.toastr.success(response.message);
      else
        this.toastr.error(response.message);

      this.reloadContent(item.providerId);

    });
  }

  editUser() {
    const x = document.getElementById("primary-seccion");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }
  }

  addAdditionalUser() {
    const x = document.getElementById("additional-user-seccion");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }

    //dinamyc title
    const y = document.getElementById("viewHideAdditionalEditor");
    if (y.innerHTML === "Add Additional Editor") {
      y.innerHTML = "Cancel";
    } else {
      y.innerHTML = "Add Additional Editor";
    }

    //empty/clear email input    
    this.additionalEmail = '';

  }
  editAdditionalUser(i: number) {
    const x = document.getElementById(`edit-additional-seccion${i}`);
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }

  }

  updateUser(item: any, userType: number) {

    let emailToUpdate = "";
    if (userType == 1)
      emailToUpdate = this.editPrimaryEmail;
    if (userType == 2)
      emailToUpdate = this.additionalEmail;

    if (!this.isEmailValid(emailToUpdate)) {
      this.toastr.error('Invalid Email', 'Error updating user');
      return;
    }

    this.adminService.editUser(item.userId, item.providerId, item.username, emailToUpdate, this.isCheckedUpdatedDisplayEmail, userType).subscribe(response => {
      console.log("edited:", response);

      if (response.success)
        this.toastr.success(response.message);
      else
        this.toastr.error(response.message);

      this.reloadContent(item.providerId);


    });

  }

  reloadContent(provId: number) {
    this.adminService.getPrimaryMgmtInfo(provId).subscribe(response => {
      console.log(response);
      this.primaryEmailMgmt = response;
      //this.name = response;

    });
  }

  isEmailValid(email: string): boolean {

    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    return expression.test(email);

    //"^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$"
    //[A-Za-z0-9._%-]+@[A-Za-z0-9._%-]+\\.[a-z]{2,3}
  }

}
