import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService, User } from 'src/app/auth/authentication.service';
import { ChildCareProvider } from 'src/app/models/child-care-provider';
import { Observable, observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../service/user.service';
import { ToastrService } from 'ngx-toastr';
import { ProviderMessagesService } from '../../service/provider-messages.service';
import { ProviderMessage } from '../../models/provider-message';
import { RequestSupport } from '../../models/request-support';
import { CapacityRequestService } from '../../service/capacity-request.service';
import { ChangePasswordModel } from '../../models/change-password-model';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.css']
})
export class NavMenuComponent implements OnInit {
  isExpanded = false;
  showMessages = false;
  currentUser: User;
  childCares: Observable<ChildCareProvider[]>;
  childCare: ChildCareProvider = null;
  unreadMessages: any[];
  license: string;
  public activeLang = 'en';
  public isImpersonation = false;
  public isAdminEditing = false; 
  isAddProviderActive = false;
  selectedMessage: ProviderMessage = new ProviderMessage();
  indexOfMessage: number;
  requestSuport: RequestSupport;
  selectedUserValue: number;
  selectedSubjectValue: number;
  isProviderSelectedOption: boolean;
  chngPwdModel: ChangePasswordModel;

  @ViewChild('toggleButton') toggleButton: ElementRef;
  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private translate: TranslateService,
    private userService: UserService,
    private toastr: ToastrService,
    private messagesService: ProviderMessagesService,
    private renderer: Renderer2,
    private childCareService: CapacityRequestService  ) {
    this.requestSuport = new RequestSupport();
    this.chngPwdModel = new ChangePasswordModel();

    if (localStorage.getItem("activeLang")) {
      this.activeLang = localStorage.getItem("activeLang");
    }
    if (localStorage.getItem("isImpersonation")) {
      this.isImpersonation = true;
      this.auth.setImpersonation(true);
    }
    if (localStorage.getItem("isAdminEditing")) {
      this.isAdminEditing = true;
      this.auth.setIsAdminEditing(true);
    }
    if (localStorage.getItem("currentProvider")) {
      this.auth.setCurrentChildCareValue(JSON.parse(localStorage.getItem("currentProvider")));
      this.isAddProviderActive = true;
    }
    this.translate.setDefaultLang(this.activeLang);
    this.currentUser = new User();
    this.unreadMessages = [];
    this.renderer.listen('window', 'click', (e: Event) => {
      if (this.toggleButton && e.target !== this.toggleButton.nativeElement) {
        this.showMessages = false;
      }
    });
  }

  ngOnInit(): void {
    this.childCares = this.auth.getUserChildCares();
    this.auth.currentUser.subscribe((user: User) => {
      this.currentUser = user;
      if (this.isImpersonation)
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    });
    this.auth.isImpersonationObservable.subscribe((res: boolean) => {
      this.isImpersonation = res;
    });
    this.auth.isAdminEditingObservable.subscribe((res: boolean) => {
      this.isAdminEditing = res;
    });
    this.auth.getCurrentChildCareObservable().subscribe(result => {
      if (result) {
        this.childCare = result;
        this.isAddProviderActive = true;
        this.unreadMessages = this.auth.getUnreadMessages();
        if (this.isImpersonation) {
          localStorage.removeItem("unreadMessages");
          this.auth.setActiveUnreadMessages(this.currentUser.id).subscribe(
            res => {
              this.unreadMessages = res
              localStorage.setItem("unreadMessages", JSON.stringify(res));
              this.unreadMessages = this.auth.getUnreadMessages();
            })


        }



      }
      else {
        this.isAddProviderActive = false;
      }
    });
    if (this.currentUser && this.currentUser.userType === 1) {
      this.messagesService.getActiveUnreadMessages(this.currentUser.id).subscribe(
        res => {
          //console.log('unreaded ', res)
          this.unreadMessages = res;
        }
      );

    }

    
  }

  selectMessage(item, index) {
    //console.log('index: ', index);
    this.indexOfMessage = index;
    this.selectedMessage = item;
    this.messagesService.readMessage(item.id).subscribe(
      res => {
        console.log('read ')
      }
    );
  }
  toggleMessages() {
    this.showMessages = !this.showMessages;
  }
  signOut(): void {
    this.auth.logout();
    localStorage.removeItem("jwt");
    this.router.navigate(['']);
  }

  setCurrentUser(user: ChildCareProvider) {
    this.auth.selectChildCare(user);
    this.router.navigate(['profile']);
  }

  openHelpPdf() {
    window.open("assets/files/help.pdf");
  }
  //openSurvey() {
  //  window.open("assets/files/1QEmbedded.html");
  //}
  openResourcesPage() {
    window.open("assets/files/resources.html");
  }
  openSiteTutorial() {
    window.open("assets/files/site-tutorial.html");
  }

  public changeLang(lang) {
    this.activeLang = lang;
    localStorage.setItem("activeLang", lang);
    this.translate.use(lang);
  }

  returntoAdmin() {
    localStorage.removeItem('isImpersonation');
    localStorage.removeItem('isAdminEditing');    
    this.auth.setImpersonation(false);
    this.auth.setIsAdminEditing(false);
    this.auth.setCurrentChildCareValue(null);
    this.router.navigate(['admin-providers']);
  }

  saveNewProvider() {
    //Get current child Care
    this.childCare = this.auth.getCurrentChildCare();
    if (this.currentUser.userType == 2) {
      this.userService.getChildCareRegisteredUser(this.childCare.id).subscribe(result => {
        if (result) {
          this.userService.registerNewUserProvider(this.license, result.id).subscribe(result => {
            this.auth.getChildCares(this.currentUser.id);
            if (result.result === "success") {
              this.toastr.success("Site Registered Successfully");
            }
            else {
              this.toastr.error(result.message);
            }
          });
        }
        else {
          this.toastr.success("Error registering the site");
        }
      });
    }
    else {
      this.userService.registerNewUserProvider(this.license, this.currentUser.id).subscribe(result => {
        this.auth.getChildCares(this.currentUser.id);
        if (result.result === "success") {
          this.toastr.success("Site Registered Successfully");
        }
        else {
          this.toastr.error(result.message);
        }
      });
    }
  }

  updateLicense(text: string) {
    this.license = text;
  }
  addRequest() {
    //this.requestSuport = new RequestSupport();
    this.requestSuport.subject = 0;
    this.requestSuport.userType = 0;
    this.requestSuport.name = '';
    this.requestSuport.email = '';
    this.requestSuport.phone = '';
    this.requestSuport.licenseNumber = '';
    this.requestSuport.message = '';
  }
  clearChangePassword() {
    this.chngPwdModel.oldPassword='';
    this.chngPwdModel.newPassword = '';
    this.chngPwdModel.confirmPassword = '';
  }
  userTypeSelected(event) {
    const selected = event;//.target.value;
    this.selectedUserValue = selected;
    if (selected === 1) {  // "Child Care Provider/Facility") 
      this.isProviderSelectedOption = true;
    } else {
      this.isProviderSelectedOption = false;
    }
    this.requestSuport.subject = 0;
  }

  selectedSubject(event) {
    const selected = event;
    this.selectedSubjectValue = selected;
  }
  createRequestSupport() {

    this.childCareService.saveHelp(this.requestSuport).subscribe(result => {
      // this.childCare = result;
      
      if (result.result === "success") {

        if (this.activeLang === "en") {
          this.toastr.success("Your help request has been submitted");
        }
        else {
          this.toastr.success("Su solicitud de ayuda ha sido enviada");
        }

      }
      else {
        
        if (this.activeLang === "en") {
          this.toastr.error("An unexpected error occurred when submitting the request. Please try again.");
        }
        else {
          this.toastr.error("Se produjo un error inesperado al enviar la solicitud. Inténtalo nuevamente.");
        }

      }

      

    });
  }

  savePassword() {
    this.chngPwdModel.userId = this.currentUser.id;
    console.log(this.chngPwdModel);
    this.userService.savePassword(this.chngPwdModel).subscribe(response => {
      console.log(response);
      if (response.result==='success') 
        this.toastr.success(response.message);        
      else
        this.toastr.error(response.message);

      
    });
  }
}
