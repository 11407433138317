import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AuthenticationService } from 'src/app/auth/authentication.service';


@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

  //  constructor(private router: Router, private authenticationService: AuthenticationService) { }

  //  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
  //      const currentUser = this.authenticationService.currentUserValue;
  //      if (currentUser) {
  //          // logged in so return true
  //          return true;
  //      }

  //      // not logged in so redirect to login page with the return url
  //      this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
  //      return false;
  //}
  constructor(private router: Router, private jwtHelper: JwtHelperService, private auth: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = localStorage.getItem("jwt");
    if (token && !this.jwtHelper.isTokenExpired(token)) {
      return true;
    }

    // not logged in so redirect to login page with the return url
    this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
    this.auth.logout();
    localStorage.removeItem("jwt");

    return false;
  }

}
