import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ChildCareProvider } from '../models/child-care-provider';
import { map, catchError } from 'rxjs/operators';
import { User, AuthenticationService } from '../auth/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { ProviderMessage } from '../models/provider-message';



@Injectable({
  providedIn: 'root'
})
export class ProviderMessagesService {
  _baseUrl: string;
  currentUser: User;

  constructor(private http: HttpClient, @Inject('BASE_URL')
  private baseUrl: string,
    private cookieService: CookieService,
    private oAuth: AuthenticationService) {
    this._baseUrl = baseUrl;
    this.oAuth.currentUser.subscribe(res =>
      this.currentUser = res
    );
  }

  getPendingMessages() {
    return this.http.get<any[]>(this._baseUrl + "api/providermessage/pending-messages");
  }
  getActiveMessages() {
    return this.http.get<any[]>(this._baseUrl + "api/providermessage/active-messages");
  }
  getPastMessages() {
    return this.http.get<any[]>(this._baseUrl + "api/providermessage/past-messages");
  }
  createMessage(message: ProviderMessage) {
    message.createdByUserId = this.currentUser.id;
    message.status = message.isHidden ? 2 : 1;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    headers.append('Authorization', 'Bearer');
    return this.http.post<any>(`${this._baseUrl}api/providermessage`, message, { headers: headers });
  }
  updateMessage(message: ProviderMessage) {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    headers.append('Authorization', 'Bearer');
    message.status = message.isHidden ? 2 : 1;
    return this.http.put<any>(`${this._baseUrl}api/providermessage/${message.id}`, message, { headers: headers });

  }

  deleteMessage(message: ProviderMessage) {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    headers.append('Authorization', 'Bearer');
    return this.http.delete<any>(`${this._baseUrl}api/providermessage/${message.id}`);
  }

  getActiveUnreadMessages(provUsrId) {
    return this.http.get<any[]>(`${this._baseUrl}api/providermessage/active-unread-messages/${provUsrId}`);
  }

  getActiveReadMessages(provUsrId) {
    return this.http.get<any[]>(`${this._baseUrl}api/providermessage/active-read-messages/${provUsrId}`);
  }

  readMessage(messageId) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    headers.append('Authorization', 'Bearer');
    var activity = {
      providerUserId: this.currentUser.id,
      messageId: messageId
    }
    return this.http.post<any>(`${this._baseUrl}api/providermessage/activity`, activity, { headers: headers });
  }
}
