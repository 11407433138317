import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
//TODO: No Longer Used - Delete in a future release.
//import { CookieService } from 'ngx-cookie-service';
import { User } from '../auth/authentication.service';
import { ChildCareProvider } from '../models/child-care-provider';
import { ChangePasswordModel } from '../models/change-password-model';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  _baseUrl: string;
  _httpAuthHeader: any;
  currentUser: User;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) { 
    this._baseUrl = baseUrl;

    this._httpAuthHeader = new HttpHeaders({ 'Content-Type': 'application/json' })

  }

  registerUserRequest(license: string): Observable<any> {
    return this.http.get<any>(`${this._baseUrl}api/user/register/${license}` , {headers : this._httpAuthHeader});
  }

  registerNewEmailRequest(license: string, email: string): Observable<any> {
    return this.http.get<any>(`${this._baseUrl}api/user/registerProviderEmail/${license}/${email}`, {headers : this._httpAuthHeader});
  }

  registerNewUserProvider(license: string, id: number) {
    return this.http.get<any>(`${this._baseUrl}api/user/registerNewUserProvider/${license}/${id}`, {headers : this._httpAuthHeader});
  }

  resetPasswordRequest(license: string): Observable<any> {
    //return this.http.get<any>(`${this._baseUrl}api/user/resetpassword/${license}`, { headers: this._httpAuthHeader });
    return this.http.post<any>(`${this._baseUrl}api/user/resetpassword`, JSON.stringify(license), { headers: this._httpAuthHeader });
  }

  setPassword(password: string, token: string): Observable<any> {
    return this.http.get<any>(`${this._baseUrl}api/user/setpassword/${password}/${token}`, {headers : this._httpAuthHeader});
  }

  validateToken(token: string): Observable<any> {
    return this.http.get<any>(`${this._baseUrl}api/user/validatetoken/${token}`, {headers : this._httpAuthHeader});
  }

  getChildCareRegisteredUser(providerId: number) {
    return this.http.get<any>(`${this._baseUrl}api/user/getUserByProviderId/${providerId}`, { headers: this._httpAuthHeader });
  }

  validateAvailabilityToken(token: string): Observable<ChildCareProvider> {
    return this.http.get<any>(`${this._baseUrl}api/availability/setvalue/${token}`, { headers: this._httpAuthHeader });
  }

  savePassword(chngPwdModel: ChangePasswordModel): Observable<any> {    
    return this.http.post<any>(`${this._baseUrl}api/user/changepassword`, chngPwdModel, { headers: this._httpAuthHeader });
  }
}

