export class ProviderMessage {
  id: string;
  title: string;
  body: string;
  startDate: string;
  endDate: string;
  createDate: string;
  createdByUserId: number;
  status: number;
  isHidden: boolean;
}
