import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { ChildCareProvider } from '../models/child-care-provider';
import { map, catchError } from 'rxjs/operators';
import { User, AuthenticationService } from '../auth/authentication.service';
import { CookieService } from 'ngx-cookie-service';
import { RequestSupport } from '../models/request-support';

@Injectable({
  providedIn: 'root'
})
export class CapacityRequestService {
  _baseUrl: string;
  currentUser: User;

  constructor(private http: HttpClient, @Inject('BASE_URL')
  private baseUrl: string,
  private cookieService: CookieService,
  private oAuth: AuthenticationService) {
    this._baseUrl = baseUrl;
    this.oAuth.currentUser.subscribe(res =>
      this.currentUser = res
    );
  }

  getChildCares(pageNumber: number, pageSize: number): Observable<any> {
    const params = new HttpParams().set("PageSize", pageSize.toString()).set("PageNumber", pageNumber.toString());

    return this.http.get(this._baseUrl + "api/childcare/getall/asc", { params: params, observe: "response" })
    .pipe(
      map((resp: any) => {
        return resp;
      }), catchError( error => {
        return throwError("Error:: " + error)
      })
    );
  }

  filterRequest(filter: FilteredRequest): Observable<any> {
    let params = new HttpParams()
      .set("PageSize", filter.pageSize.toString())
      .set("PageNumber", filter.pageNumber.toString())
      .set("OpenAccept", filter.openAccept.toString())
      .set("OpenNotAccept", filter.openNotAccept.toString())
      .set("Closed", filter.closed.toString())
      .set("Infant", filter.infatnt.toString())
      .set("Toddler", filter.toddler.toString())
      .set("Preschool", filter.preschool.toString())
      .set("SchoolAge", filter.schoolAge.toString())
      .set("ProviderType", filter.ProviderType.toString());

    if (filter.zipCode !== null) params = params.append("ZipCode", filter.zipCode);
    if (filter.distance !== null) params = params.append("Distance", filter.distance);
    if (filter.keyWord !== null) params = params.append("Keyword", filter.keyWord);
    if (filter.AcceptsSubsidy !== null) params = params.append("AcceptsSubsidy", filter.AcceptsSubsidy.toString());
    if (filter.StepUptoQuality !== null) params = params.append("StepUptoQuality", filter.StepUptoQuality.toString());
    if (filter.spanish !== null) params= params.append("Spanish", filter.spanish.toString());
    if (filter.disability !== null) params = params.append("Disability", filter.disability.toString());
    if (filter.searchLat !== null) params = params.append("SearchLat", filter.searchLat.toString());
    if (filter.searchLng !== null) params = params.append("SearchLng", filter.searchLng.toString());
    if (filter.IsRegistered !== null) params = params.append("IsRegistered", filter.IsRegistered.toString());
    if (filter.IsRemoteLearningAvailable !== null) params = params.append("IsRemoteLearningAvailable", filter.IsRemoteLearningAvailable.toString());
    if (filter.isTransportationProvided !== null) params = params.append("IsTransportationProvided", filter.isTransportationProvided.toString());
    if (filter.NextInfantSlotAvailable !== null) params = params.append("NextInfantSlotAvailable", filter.NextInfantSlotAvailable);
    if (filter.foodAllergiesNeeds !== null) params = params.append("foodAllergiesNeeds", filter.foodAllergiesNeeds.toString());
    if (filter.TimeOpenClosed !== null) params = params.append("TimeOpenClosed", filter.TimeOpenClosed);
    if (filter.isWheelchairAccessible !== null) params = params.append("IsWheelchairAccessible", filter.isWheelchairAccessible.toString());
    if (filter.isParentingClasses !== null) params = params.append("isParentingClasses", filter.isParentingClasses.toString());
    if (filter.isEngagementOpportunities !== null) params = params.append("isEngagementOpportunities", filter.isEngagementOpportunities.toString());
    if (filter.isConnectionToServices !== null) params = params.append("isConnectionToServices", filter.isConnectionToServices.toString());
    if (filter.isProvideRespiteCare !== null) params = params.append("isProvideRespiteCare", filter.isProvideRespiteCare.toString());
    if (filter.isDropInCare !== null) params = params.append("isDropInCare", filter.isDropInCare.toString());

    return this.http.get(this._baseUrl + "api/childcare/filter", { params: params, observe: "response" })
    .pipe(
      map((resp: any) => {
        return resp;
      }), catchError( error => {
        return throwError("createOrder: " + error)
      })
    );
  }

 /* searchChildCares(term: string, pageNumber: number, pageSize: number): Observable<any> {
    const params = new HttpParams().set("PageSize", pageSize.toString()).set("PageNumber", pageNumber.toString());
    return this.http.get(`${this._baseUrl }api/ChildCare/search/${term}/asc`, { params: params, observe: "response" })
    .pipe(
      map((resp: any) => {
        return resp;
      }), catchError( error => {
        return throwError("createOrder: " + error)
      })
    );
  }*/

  getZipCodeSearch(filter: FilteredRequest): any {
    const params = new HttpParams()
    .set("PageSize", filter.pageSize.toString())
    .set("PageNumber", filter.pageNumber.toString())
    .set("OpenAccept", filter.openAccept.toString())
    .set("OpenNotAccept", filter.openNotAccept.toString())
    .set("Closed", filter.closed.toString())
    .set("Infant", filter.infatnt.toString())
    .set("Toddler", filter.toddler.toString())
    .set("Preschool", filter.preschool.toString())
    .set("SchoolAge", filter.schoolAge.toString())
    //.set("AcceptsSubsidy", filter.AcceptsSubsidy.toString())
    //.set("StepUptoQuality", filter.StepUptoQuality.toString())
    .set("ProviderType", filter.ProviderType.toString())
    .set("ZipCode", filter.zipCode)
    .set("Distance", filter.distance);
    return this.http.get<any>(`${this._baseUrl }api/ChildCare/zipcodes`, { params: params, observe: "response" });
  }

  updateChildCare(provider: ChildCareProvider, userId:number, SectionId: number): Observable<ChildCareProvider> {
    let adminUser: User = null;
    if (localStorage.getItem("isImpersonation")) {
      adminUser = JSON.parse(localStorage.getItem("adminUser"));
    }
    const headers = new HttpHeaders({'Content-Type':'application/json'})
    headers.append('Authorization','Bearer');
    var updateDto = {
      "Provider": provider,
      "UserId": userId,
      "SectionId":SectionId,
      "UserImpersonatingId" : (adminUser) ? adminUser.id : null
    }
    return this.http.post<any>(`${this._baseUrl}api/ChildCare/updateChildCare`, updateDto, { headers: headers});
  }

  updateAvailability(provider: ChildCareProvider, userId: number, SectionId: number): Observable<ChildCareProvider> {
    let adminUser: User = null;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    headers.append('Authorization', 'Bearer');
    var updateDto = {
      "Provider": provider,    
      "UserId": userId,
      "SectionId": SectionId,
      "UserImpersonatingId": adminUser
    }

    return this.http.post<any>(`${this._baseUrl}api/ChildCare/updateAvailability`, updateDto, { headers: headers });
  }

  //only update AvailabilityLastUpdated field
  updateAvailabilityLastUpdated(provider: ChildCareProvider): Observable<ChildCareProvider> {
   
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    headers.append('Authorization', 'Bearer');
    const updateDto = {
      "Provider": provider     
    }

    return this.http.post<any>(`${this._baseUrl}api/ChildCare/UpdateAvailabilityLastUpdated`, updateDto, { headers: headers });
  }

  adminUpdateChildCare(provider: ChildCareProvider): Observable<any> {
    const headers = new HttpHeaders({'Content-Type':'application/json'})
    headers.append('Authorization','Bearer');
    return this.http.post<any>(`${this._baseUrl}api/ChildCare/adminupdateChildCare`, provider, { headers: headers});
  }

  uploadChildCareProfileImg(id: number, file: string): Observable<any>{
    let formData: FormData = new FormData();
    formData.append('image', file);
    formData.append('id', id.toString());    

    return this.http.post<any>(`${this._baseUrl}api/ChildCare/uploadImage`, formData);
  }

  uploadDhhcFile(file: any) {
    let formData: FormData = new FormData();
    formData.append('file', file);

    return this.http.post<any>(`${this._baseUrl}api/ChildCare/uploadDhhcFile`, formData);
  }

  getLocations(filter: FilteredRequest, center: any): Observable<any>  {
    let params = new HttpParams()
    .set("PageSize", filter.pageSize.toString())
    .set("PageNumber", filter.pageNumber.toString())
    .set("OpenAccept", filter.openAccept.toString())
    .set("OpenNotAccept", filter.openNotAccept.toString())
    .set("Closed", filter.closed.toString())
    .set("Infant", filter.infatnt.toString())
    .set("Toddler", filter.toddler.toString())
    .set("Preschool", filter.preschool.toString())
    .set("SchoolAge", filter.schoolAge.toString())
    .set("ProviderType", filter.ProviderType.toString());

    if (filter.zipCode !== null) params = params.append("ZipCode", filter.zipCode);
    if (filter.distance !== null) params = params.append("Distance", filter.distance);
    if (filter.keyWord !== null) params = params.append("Keyword", filter.keyWord);
    if (filter.AcceptsSubsidy !== null) params = params.append("AcceptsSubsidy", filter.AcceptsSubsidy.toString());
    if (filter.StepUptoQuality !== null) params = params.append("StepUptoQuality", filter.StepUptoQuality.toString());
    if (filter.spanish !== null) params = params.append("Spanish", filter.spanish.toString());
    if (filter.disability !== null) params = params.append("Disability", filter.disability.toString());
    if (filter.searchLat !== null) params = params.append("SearchLat", filter.searchLat.toString());
    if (filter.searchLng !== null) params = params.append("SearchLng", filter.searchLng.toString());
    if (filter.IsRegistered !== null) params = params.append("IsRegistered", filter.IsRegistered.toString());
    if (filter.IsRemoteLearningAvailable !== null) params = params.append("IsRemoteLearningAvailable", filter.IsRemoteLearningAvailable.toString());
    if (filter.isTransportationProvided !== null) params = params.append("IsTransportationProvided", filter.isTransportationProvided.toString());
    if (filter.NextInfantSlotAvailable !== null) params = params.append("NextInfantSlotAvailable", filter.NextInfantSlotAvailable);
    if (filter.foodAllergiesNeeds !== null) params = params.append("foodAllergiesNeeds", filter.foodAllergiesNeeds.toString());
    if (filter.TimeOpenClosed !== null) params = params.append("TimeOpenClosed", filter.TimeOpenClosed);
    if (filter.isWheelchairAccessible !== null) params = params.append("IsWheelchairAccessible", filter.isWheelchairAccessible.toString());
    if (filter.isParentingClasses !== null) params = params.append("isParentingClasses", filter.isParentingClasses.toString());
    if (filter.isEngagementOpportunities !== null) params = params.append("isEngagementOpportunities", filter.isEngagementOpportunities.toString());
    if (filter.isConnectionToServices !== null) params = params.append("isConnectionToServices", filter.isConnectionToServices.toString());
    if (filter.isProvideRespiteCare !== null) params = params.append("isProvideRespiteCare", filter.isProvideRespiteCare.toString());
    if (filter.isDropInCare !== null) params = params.append("isDropInCare", filter.isDropInCare.toString());

    return this.http.get(this._baseUrl + "api/childcare/getlocations", { params: params, observe: "response" })
        .pipe(
        map((resp: any) => {
          return resp;
        }), catchError( error => {
          return throwError("createOrder: " + error)
        })
      );
  }

  getLogs() {
    return this.http.get<any[]>(this._baseUrl + "api/childcare/getLogs");
  }
  getUsageReport() {
    return this.http.get<any[]>(this._baseUrl + "api/childcare/usage-report");
  }
  getProviderMarker(providerId: number) {
    return this.http.get<any>(`${this._baseUrl}api/childcare/provider-info-marker/${providerId}`);
  }

  getLogDetails(id: number) {
    return this.http.get<any[]>(`${this._baseUrl}api/childcare/getLogsDetails/${id}`);
  }

  filterRequestExcel(filter: FilteredRequest): Observable<any> {
    let params = new HttpParams()
      .set("PageSize", filter.pageSize.toString())
      .set("PageNumber", filter.pageNumber.toString())
      .set("OpenAccept", filter.openAccept.toString())
      .set("OpenNotAccept", filter.openNotAccept.toString())
      .set("Closed", filter.closed.toString())
      .set("Infant", filter.infatnt.toString())
      .set("Toddler", filter.toddler.toString())
      .set("Preschool", filter.preschool.toString())
      .set("SchoolAge", filter.schoolAge.toString())
      .set("ProviderType", filter.ProviderType.toString());


    if (filter.keyWord !== null) params = params.append("Keyword", filter.keyWord);
    if (filter.IsRegistered !== null) params = params.append("IsRegistered", filter.IsRegistered.toString());
    if (filter.IsTestLicense !== null) params = params.append("IsTestLicense", filter.IsTestLicense.toString());
    if (filter.LastLoginFilterOption !== null) params = params.append("LastLoginEnum", filter.LastLoginFilterOption);

    return this.http.get(this._baseUrl + "api/childcare/filterexcel", { params: params, observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }), catchError(error => {
          return throwError("createOrder: " + error)
        })
      );
  }

  filterProviderByAdmin(filter: FilteredRequest): Observable<any> {
    let params = new HttpParams()
      .set("PageSize", filter.pageSize.toString())
      .set("PageNumber", filter.pageNumber.toString())
      .set("OpenAccept", filter.openAccept.toString())
      .set("OpenNotAccept", filter.openNotAccept.toString())
      .set("Closed", filter.closed.toString())
      .set("Infant", filter.infatnt.toString())
      .set("Toddler", filter.toddler.toString())
      .set("Preschool", filter.preschool.toString())
      .set("SchoolAge", filter.schoolAge.toString())
      .set("ProviderType", filter.ProviderType.toString());


    if (filter.keyWord !== null) params = params.append("Keyword", filter.keyWord);
    if (filter.IsRegistered !== null) params = params.append("IsRegistered", filter.IsRegistered.toString());
    if (filter.IsTestLicense !== null) params = params.append("IsTestLicense", filter.IsTestLicense.toString());
    if (filter.LastLoginFilterOption !== null) params = params.append("LastLoginEnum", filter.LastLoginFilterOption);

    return this.http.get(this._baseUrl + "api/childcare/filterProviderByAdmin", { params: params, observe: "response" })
      .pipe(
        map((resp: any) => {
          return resp;
        }), catchError(error => {
          return throwError("createOrder: " + error)
        })
      );
  }

  forceRegistration(provider: ChildCareProvider, optionId: number) {
    const headers = new HttpHeaders({'Content-Type':'application/json'})
    headers.append('Authorization', 'Bearer');
    return this.http.post<any>(`${this._baseUrl}api/user/forceregistration/${optionId.toString()}`, provider, { headers: headers });
  }

  saveHelp(help: RequestSupport) {

    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    headers.append('Authorization', 'Bearer');
    return this.http.post<any>(`${this._baseUrl}api/ChildCare/saveHelp`, help, { headers: headers });
  }

}

//TODO: move to a separated file
export class FilteredRequest {

  constructor(_pageNumber: number, _pageSize: number)
  {
    this.pageNumber = _pageNumber;
    this.pageSize = _pageSize;
    this.openAccept = false;
    this.openNotAccept = false;
    this.closed = false;
    this.infatnt = false;
    this.toddler = false;
    this.preschool = false;
    this.schoolAge = false;
    this.AcceptsSubsidy = null;
    this.StepUptoQuality = null;
    this.ProviderType = "1,2,3,4,5,6,7,8,9,10",
    this.zipCode = null;
    this.distance = null;
    this.keyWord = null;
    this.spanish = null;
    this.disability = null;
    this.searchLat = null;
    this.searchLng = null;
    this.IsRegistered = null;
    this.IsRemoteLearningAvailable = null;
    this.isTransportationProvided = null;
    this.IsTestLicense = null;
    this.NextInfantSlotAvailable = null;
    this.TimeOpenClosed = null;
    this.LastLoginFilterOption = null;
    this.isWheelchairAccessible = null;    
    this.foodAllergiesNeeds = null;
    this.isParentingClasses = null;
    this.isEngagementOpportunities = null;
    this.isConnectionToServices = null;
    this.isProvideRespiteCare = null;
    this.isDropInCare = null;
  }

  pageNumber: number;
  pageSize: number;
  openAccept: boolean;
  openNotAccept: boolean;
  closed: boolean;
  infatnt: boolean;
  toddler: boolean;
  preschool: boolean;
  schoolAge: boolean;
  AcceptsSubsidy: boolean;
  StepUptoQuality: boolean;
  ProviderType: string;
  zipCode: string;
  distance: string;
  keyWord: string;
  spanish:boolean;
  disability: boolean;
  searchLat: number;
  searchLng: number;
  IsRegistered: boolean;
  IsRemoteLearningAvailable: boolean;
  IsTestLicense: boolean;
  isTransportationProvided: boolean;
  NextInfantSlotAvailable: string;
  TimeOpenClosed: string;
  LastLoginFilterOption: string;
  isWheelchairAccessible: boolean;
  foodAllergiesNeeds: boolean;
  isParentingClasses: boolean;
  isEngagementOpportunities: boolean;
  isConnectionToServices: boolean;
  isProvideRespiteCare: boolean;
  isDropInCare: boolean;
}
