/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

export function getBaseUrl() {
  return document.getElementsByTagName('base')[0].href;
}

const providers = [
  { provide: 'BASE_URL', useFactory: getBaseUrl, deps: [] }
];

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic(providers).bootstrapModule(AppModule)
  .catch(err => console.log(err));

bootstrapApplication(PrimaryEmailMgmtComponent, {
  providers: [
    provideHttpClient(withNoXsrfProtection()),
  ],
})
  .then(ref => {
    // Ensure Angular destroys itself on hot reloads.
    if (window['ngRef']) {
      window['ngRef'].destroy();
    }
    window['ngRef'] = ref;

    // Otherwise, log the boot error
  })
  .catch(err => console.error(err));

//platformBrowserDynamic().bootstrapModule(AppModule).then(ref => {
//  // Ensure Angular destroys itself on hot reloads.
//  if (window['ngRef']) {
//    window['ngRef'].destroy();
//  }
//  window['ngRef'] = ref;

//  // Otherwise, log the boot error
//}).catch(err => console.error(err));

import 'zone.js';  // Included with Angular CLI.
import { bootstrapApplication } from '@angular/platform-browser';
import { PrimaryEmailMgmtComponent } from './app/components/primary-email-mgmt/primary-email-mgmt.component';
import { provideHttpClient, withNoXsrfProtection } from '@angular/common/http';

//export { renderModule, renderModuleFactory } from '@angular/platform-server';
