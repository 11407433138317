import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.css']
})
export class SetPasswordComponent {
  userName: string;
  password: string;
  confirmPass: string;
  message: string;
  token: string;
  valid = false;
  complete = false;
  activeLang = 'en';

  constructor(private route: ActivatedRoute, private userService: UserService) {
    this.token = this.route.snapshot.paramMap.get("token");
    //this.userName = this.route.snapshot.paramMap.get("userName");
    this.userService.validateToken(this.token).subscribe(res => {
      if(res.result === "success") {
        this.valid = true;
      }
    });
    if (localStorage.getItem("activeLang"))
      this.activeLang = localStorage.getItem("activeLang");
  }

  setPassword() {
    if (this.validatePassword()) {
      this.userService.setPassword(this.password, this.token).subscribe(res => {
      if(res.result === "success") this.complete = true;
    });
    }
  }

  validatePassword(): boolean {
    if (!this.password || !this.confirmPass) {
      if (this.activeLang === 'en')
        this.message = "Both fields must be completed";
      else
        this.message = "Ambos campos deben ser completados";
      return false;
    }
    if (this.password.length < 8)
    {
      if (this.activeLang === 'en')
        this.message = "Password must have more than 8 characters";
      else
        this.message = "El password debe tener mas de 8 caracteres";
      return false;
    }
    if (this.password !== this.confirmPass) {
      if (this.activeLang === 'en')
        this.message = "The passwords must match";
      else
        this.message = "Los password deben ser iguales";
      return false;
    }
    return true;

  }
  updatePassword(val: string)
  {
    this.password = val;
  }

  updateConfirm(val: string) {
    this.confirmPass = val;
  }
}
