import { Component, OnInit } from '@angular/core';
import { AuthenticationService, User } from '../auth/authentication.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
})
export class HomeComponent {
  currentUser: User;
  isLoggedIn = false;
  constructor(private auth: AuthenticationService) {


  }

  //ngOnInit(): void {

  // // this.isLoggedIn = localStorage.getItem("currentUser") != null;

  //}

  ngAfterViewInit(): void {
    //let imper = localStorage.getItem("isImpersonation");
    //let cuser = localStorage.getItem("currentUser");

    this.auth.currentUser.subscribe((user: User) => {
      this.currentUser = user;

      this.isLoggedIn = user != null;
    });

  }

}
