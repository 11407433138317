import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { User, AuthenticationService } from '../auth/authentication.service';
import { ProviderReminderSettings } from '../models/provider-reminder-settings';

@Injectable({
  providedIn: 'root'
})
export class ProviderReminderSettingsService {
  _baseUrl: string;
  currentUser: User;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string,
    private cookieService: CookieService,
    private oAuth: AuthenticationService) {
    this._baseUrl = baseUrl;
    this.oAuth.currentUser.subscribe(res =>
      this.currentUser = res
    );
  }

  getProviderReminderSettings(providerId: number) {
    return this.http.get<ProviderReminderSettings>(`${this._baseUrl}/api/ProviderRemindersSettings/get-setting/${providerId}`);
  }

  saveProviderReminderSettings(reminder: ProviderReminderSettings, providerId: number) {
    reminder.providerId = providerId;
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    headers.append('Authorization', 'Bearer');

    return this.http.post<ProviderReminderSettings>(`${this._baseUrl}api/ProviderRemindersSettings`, reminder, { headers: headers });
  }

  deleteReminderSettings(providerId: number) {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
    headers.append('Authorization', 'Bearer');

    return this.http.post<any>(`${this._baseUrl}api/ProviderRemindersSettings/subscribed-off/${providerId}`, { headers: headers });
  }
}
