import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { CapacityRequestService, FilteredRequest } from 'src/app/service/capacity-request.service';
import { ProviderMessagesService } from 'src/app/service/provider-messages.service';
import { AdminService } from 'src/app/service/admin.service';
import { ChildCareProvider } from 'src/app/models/child-care-provider';
import { ToastrService } from 'ngx-toastr';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';
import { ngxCsv } from 'ngx-csv/ngx-csv';
import { AuthenticationService } from 'src/app/auth/authentication.service';
import { UserService } from '../../service/user.service';
import { ProviderMessage } from '../../models/provider-message';
import { NgbActiveModal, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { PrimaryEmailMgmtComponent } from '../../components/primary-email-mgmt/primary-email-mgmt.component';

export class NgbdModalContent {
  //@Input() name;

  constructor(public activeModal: NgbActiveModal) { }
}

@Component({
  selector: 'app-admin-providers',
  templateUrl: './admin-providers.component.html',
  styleUrls: ['./admin-providers.component.css']
})
export class AdminProvidersComponent implements OnInit {

  
  modal: NgbModalRef;
  QuillConfiguration = {
    toolbar: [
      ['bold', 'italic', 'underline', 'strike'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      [{ color: [] }, { background: [] }],
      ['link'],
      ['clean'],
    ],
  }
  editorStyle = {
    height: '250px'
  }
  filter: FilteredRequest;
  childCares: ChildCareProvider[];
  childCaresExcel: ChildCareProvider[];
  excelList = [];
  selectedChildCare: ChildCareProvider;
  selectedMessage: ProviderMessage;
  previousPage = 0;
  nextPage = 2;
  totalCount: number;
  totalPages: number;
  hasNext: boolean;
  hasPrevious: boolean;
  file: any;
  ingestionLogs: any[];
  messages: any[];
  activeMessages: any[];
  pastMessages: any[];
  pendingMessages: any[];
  logDetail: any[];
  upload = false;
  showlogDetails = false;
  registerOptionId = 1;
  isRegisterComboText = "main.allPlaceHolder";
  loading = false;
  isEdit = false;
  copy = false;
  bodyLength = 0;
  buttonDisabled = true;
  usageReport: any[];

  options = {
    fieldSeparator: ',',
    quoteStrings: '"',
    decimalseparator: '.',
    showLabels: true,
    showTitle: false,
    title: 'Providers',
    useBom: true,
    noDownload: false,
    //headers: ["Name", "LicenseNumber", "Type", "Email", "PhoneNumber", "ZipCode", "State", "isOpen", "isRegistered", "LocationLat", "LocationLon", "InfantSlot", "ToddlerSlot", "PreschoolSlot","SchoolAgeSlot","Capacity","AgesFrom","AgesTo"],
    headers: ["FacilityName", "ContactName", "FacilityLicenseNumber", "FacilityEmail", "FacilityAltEmail", "FacilityPhoneNumber", "FacilityAddress", "FacilityCity", "FacilityZipCode", "FacilityState", "FacilityCounty", "DisplayFacilityPhoneNumber", "DisplayFacilityAddress", "DisplayFacilityCity", "DisplayFacilityZipCode", "DisplayFacilityState", "DisplayWebsite", "DisplayEmail", "FacilityType", "Spanish", "Disability", "OpenStatusId", "IsSubsidy", "IsStepUpToQuality", "InfantAvailability", "ToddlerAvailability", "PreschoolAvailability", "SchoolAgeAvailability", "LocationLat", "LocationLon", "IsRegistered", "IsApplicationPhoneNumberCellPhone", "IsRemoteLearningAvailable", "AgesFrom", "AgesTo", "DHHSCapacity", "SUTQRating", "DHHSImportDate", "InfantEnrollment", "ToddlerEnrollment", "PreschoolEnrollment", "SchoolAgeEnrollment", "EnrollmentTotal", "Status", "RegistrationDate", "ProviderDataSource", "Primary Editor", "Last Login Date - Primary", "Additional Editor 1", "Last Login Date - Editor 1", "Additional Editor 2", "Last Login Date - Editor 2", "ProviderId", "DHHSMondayHrs", "DHHSTuesdayHrs", "DHHSWednesdayHrs", "DHHSThursdayHrs", "DHHSFridayHrs", "DHHSSaturdayHrs", "DHHSSundayHrs",  "StandarizedMondayStart", "StandarizedMondayEnd", "StandarizedTuesdayStart", "StandarizedTuesdayEnd", "StandarizedWednesdayStart", "StandarizedWednesdayEnd", "StandarizedThursdayStart", "StandarizedThursdayEnd", "StandarizedFridayStart", "StandarizedFridayEnd", "StandarizedSaturdayStart", "StandarizedSaturdayEnd", "StandarizedSundayStart", "StandarizedSundayEnd"],
    eol: '\n'
  };

  constructor(
    private capacityRequestService: CapacityRequestService,
    private toastr: ToastrService,
    private cookieService: CookieService,
    private router: Router,
    private authService: AuthenticationService,
    private userService: UserService,
    private messagesService: ProviderMessagesService,
    private adminService: AdminService,
    private modalService: NgbModal
  ) {
    this.selectedChildCare = new ChildCareProvider();
    var user = JSON.parse(localStorage.getItem("adminUser"));
    if (user) {
      const dateNow = new Date();
      dateNow.setHours(dateNow.getHours() + 1);
      this.cookieService.set("currentUser", JSON.stringify(user), dateNow);
      this.authService.setCurrentUser(user);
      this.authService.clearChildCares();
      localStorage.removeItem('currentProvider');
      localStorage.removeItem('isImpersonation');
    }
    this.activeMessages = [];
    this.pendingMessages = [];
    this.pastMessages = [
    ];
  }

  maxLength(e) {
    this.bodyLength = e.editor.getLength();
    if (this.bodyLength >= 400) {
      e.editor.deleteText(400, e.editor.getLength());
    }
  }
  open(provider: ChildCareProvider) {
    localStorage.setItem("currentProvider", JSON.stringify(provider.id));
    const modalRef = this.modalService.open(PrimaryEmailMgmtComponent, { size: 'lg' });
    modalRef.componentInstance.name = provider;
    modalRef.result.then((result) => {
      if (result) {
        console.log('object to modal: ',result);
      }
    });
  }

  openMngtUsers(provider: ChildCareProvider) {
    localStorage.setItem("currentProvider", JSON.stringify(provider.id));
    //this.router.navigate(['primary-email']);
  }


  ngOnInit(): void {
    if (this.validUser()) {

      this.loadProviderMessages();

      this.filter = new FilteredRequest(1, 30);
      this.capacityRequestService.filterProviderByAdmin(this.filter).subscribe(result => {
        const headers = JSON.parse(result.headers.get("X-Pagination"));
        this.childCares = result.body;
        this.totalCount = headers.TotalCount;
        this.totalPages = headers.TotalPages;
        this.hasNext = headers.HasNext;
        this.hasPrevious = headers.HasPrevious;
        this.selectedChildCare = this.childCares[0];
        this.selectedMessage = new ProviderMessage();
      });
      this.getLogs();
      this.getUsageReport();
      this.authService.clearChildCares();
    }
    else {
      this.router.navigate(['']);
    }
  }
  onSubmit() {

    this.saveMessage();
  }
  pageRequest(page: number) {
    this.calculatePage(page);
    this.capacityRequestService.filterProviderByAdmin(this.filter).subscribe(result => {
      const headers = JSON.parse(result.headers.get("X-Pagination"));
      this.childCares = result.body;
      this.totalCount = headers.TotalCount;
      this.totalPages = headers.TotalPages;
      this.hasNext = headers.HasNext;
      this.hasPrevious = headers.HasPrevious;
    });
  }
  addMessageButtonClicked() {
    this.isEdit = false;
    this.selectedMessage = new ProviderMessage();
    this.selectedMessage.isHidden = true
  }
  selectChildCare(childCare: ChildCareProvider) {
    this.selectedChildCare = childCare;
//    console.log(childCare);
  }

  selectMessage(message: ProviderMessage) {
    this.isEdit = true;
    this.copy = false;
    this.selectedMessage = new ProviderMessage();
    this.selectedMessage.id = message.id;
    this.selectedMessage.title = message.title;
    this.selectedMessage.body = message.body;
    this.selectedMessage.startDate = message.startDate;
    this.selectedMessage.endDate = message.endDate;
    this.selectedMessage.createDate = message.createDate;
    this.selectedMessage.createdByUserId = message.createdByUserId;
    this.selectedMessage.status = message.status;
    this.selectedMessage.isHidden = message.isHidden;
  }
  copyMessage(message: ProviderMessage) {
    this.isEdit = false;
    this.copy = true;
    this.selectedMessage = message;
  }

  testAdminController(id: BigInteger) {
    this.adminService.testAdmin(id).subscribe(response => { alert(response); });
  }

  getPrimaryMgmtInfo() {
    console.log(this.selectedChildCare.id);
    this.adminService.getPrimaryMgmtInfo(this.selectedChildCare.id).subscribe(response => { console.log(response); });
  }

  saveChanges() {
    if (this.selectedChildCare.applicationPhoneNumber != null && this.selectedChildCare.applicationPhoneNumber != "") {
      if (!this.validateApplicationNumber(this.selectedChildCare.applicationPhoneNumber)) {
        this.toastr.error("Application phone number must have 10 digits and must contain only numbers.");
        return;
      }
    }
    this.capacityRequestService.adminUpdateChildCare(this.selectedChildCare).subscribe(result => {
      if (result.result === "error") {
        this.toastr.error(result.message);
      }
      else {
        this.capacityRequestService.filterProviderByAdmin(this.filter).subscribe(response => {
          const headers = JSON.parse(response.headers.get("X-Pagination"));
          this.childCares = response.body;
          this.totalCount = headers.TotalCount;
          this.totalPages = headers.TotalPages;
          this.hasNext = headers.HasNext;
          this.hasPrevious = headers.HasPrevious;
          this.selectedChildCare = result;
          ($('#exampleModal') as any).modal('hide');
          this.toastr.success("Changes Saved Successfully");
        });
      }
    }
    );
  }
  loadProviderMessages() {
    this.messagesService.getPendingMessages().subscribe(
      result => {
        this.pendingMessages = result;
      }
    );
    this.messagesService.getActiveMessages().subscribe(
      result => {
        this.activeMessages = result;
      }

    );
    this.messagesService.getPastMessages().subscribe(
      result => {
        this.pastMessages = result;
      }
    );
  }
  saveMessage() {
    if (!this.isEdit || this.copy) {
      this.messagesService.createMessage(this.selectedMessage).subscribe(response => {
        this.toastr.success("Message successfully created")
        this.loadProviderMessages();
      });
    } else {
      this.messagesService.updateMessage(this.selectedMessage).subscribe(response => {
        this.toastr.success("Message successfully updated")
        this.loadProviderMessages();
      });
    }
  }
  deleteMessage() {
    this.messagesService.deleteMessage(this.selectedMessage).subscribe(response => {
      this.toastr.success("Message successfully deleted")
      this.loadProviderMessages();
    });
  }
  searchChildCares(term: string): void {
    if (term !== "") this.filter.keyWord = term;
    else this.filter.keyWord = null;
  }

  calculatePage(currentPage: number): void {
    this.filter.pageNumber = currentPage;
    this.previousPage = currentPage - 1;
    this.nextPage = currentPage + 1;
  }

  validUser(): boolean {
    if (!this.cookieService.get("currentUser")) return false;
    let user = JSON.parse(this.cookieService.get("currentUser"));
    if (user.userType !== 2) return false;
    return true;
  }

  uploadChidCaresFile(file: any) {
    this.file = file;
    this.upload = true;
    this.capacityRequestService.uploadDhhcFile(this.file).subscribe(res => {
      if (res.result === "success") {
        this.upload = false;
        this.toastr.success("File uploaded successfully");
        this.capacityRequestService.getLogs().subscribe(res => {
          this.ingestionLogs = res;
          let id = res[0].id;
          this.getLogDetails(id);
        }

        );
      }
      else {
        this.toastr.error(res.message);
        this.upload = false;
        // this.router.navigate(['admin-providers']);
      }
    });
  }

  getLogs() {
    this.capacityRequestService.getLogs().subscribe(res => {
      if (res.length > 0) {
        this.ingestionLogs = res;
      }
      else
        this.ingestionLogs = null;
    });
  }

  getUsageReport() {
    this.capacityRequestService.getUsageReport().subscribe(res => {
      if (res.length > 0) {
        this.usageReport = res;
      }
      else
        this.usageReport = null;
    });
  }

  getLogDetails(id: number) {
    this.showlogDetails = true;
    this.capacityRequestService.getLogDetails(id).subscribe(res => {
      if (res.length > 0) {
        this.logDetail = res;
      }
      else
        this.logDetail = null;
    });
  }

  hideDetail() {
    this.showlogDetails = !this.showlogDetails;
  }

  downloadFile(uri: string) {
    window.open(uri);
  }


  buildRegisteredRequest(event: any) {

    if (event === "1") {
      this.filter.IsRegistered = true;
      this.isRegisterComboText = "admin.Registered_Is";
    }
    if (event === "2") {
      this.filter.IsRegistered = false;
      this.isRegisterComboText = "admin.Registered_Not";
    }
    if (event === "default") {
      this.filter.IsRegistered = null;
      this.isRegisterComboText = "admin.allPlaceHolder";
    }
  }

  buildLastLoginRequest(event: any) {

    if (event === "1") {
      this.filter.LastLoginFilterOption = event;
     // this.isRegisterComboText = "admin.Registered_Is";
    }
    if (event === "2") {
      this.filter.IsRegistered = false;
      //this.isRegisterComboText = "admin.Registered_Not";
    }
    if (event === "default") {
      this.filter.IsRegistered = null;
      //this.isRegisterComboText = "admin.allPlaceHolder";
    }
  }

  SelectLastLogin(text: string) {

    if (text !== "0")
      this.filter.LastLoginFilterOption = text;
    else {
      this.filter.LastLoginFilterOption = null;
    }
  }

  buildIsTestLicenseRequest(event: any) {
    this.filter.IsTestLicense = event.checked;
  }

  findProviders() {
    this.loading = true;
    this.capacityRequestService.filterProviderByAdmin(this.filter).subscribe(result => {
      const headers = JSON.parse(result.headers.get("X-Pagination"));
      this.childCares = result.body;
      this.totalCount = headers.TotalCount;
      this.totalPages = headers.TotalPages;
      this.hasNext = headers.HasNext;
      this.hasPrevious = headers.HasPrevious;

    });
  }

  downloadCSV() {

    this.childCaresExcel = [];
    this.excelList = [];
    this.capacityRequestService.filterRequestExcel(this.filter).subscribe(result => {

      this.childCaresExcel = result.body;

      for (let i = 0; i < this.childCaresExcel.length; i++) {

        //deleting lasted column 
        //delete this.childCaresExcel[i].standarizedHours.childCareProviders;
        // logic to replace display info, if these field are null then set main info.

        if (this.childCaresExcel[i].displayFacilityAddress == '' || this.childCaresExcel[i].displayFacilityAddress == null)
          this.childCaresExcel[i].displayFacilityAddress = this.childCaresExcel[i].facilityAddress;

        if (this.childCaresExcel[i].displayFacilityCity == '' || this.childCaresExcel[i].displayFacilityCity == null)
          this.childCaresExcel[i].displayFacilityCity = this.childCaresExcel[i].facilityCity;

        if (this.childCaresExcel[i].displayFacilityState == '' || this.childCaresExcel[i].displayFacilityState == null)
          this.childCaresExcel[i].displayFacilityState = this.childCaresExcel[i].facilityState;

        if (this.childCaresExcel[i].displayFacilityZipCode == '' || this.childCaresExcel[i].displayFacilityZipCode == null)
          this.childCaresExcel[i].displayFacilityZipCode = this.childCaresExcel[i].facilityZipCode;

        if (this.childCaresExcel[i].displayFacilityPhoneNumber == '' || this.childCaresExcel[i].displayFacilityPhoneNumber == null)
          this.childCaresExcel[i].displayFacilityPhoneNumber = this.childCaresExcel[i].facilityPhoneNumber;

        //SUM each capacity
        this.childCaresExcel[i].providerCapacity = this.childCaresExcel[i].infantCapacity + this.childCaresExcel[i].toddlerCapacity + this.childCaresExcel[i].preschoolCapacity + this.childCaresExcel[i].schoolAgeCapacity;
        //format insertedDHHSDate date
        this.childCaresExcel[i].insertedDHHSDate = this.childCaresExcel[i].insertedDHHSDate.substring(0, 10); 

        this.childCaresExcel[i].openStatusIdDescription = this.getOpenStatusDescription(this.childCaresExcel[i]);
        const excel = (({ facilityName, contactName, facilityLicenseNumber, facilityEmail, facilityAltEmail, facilityPhoneNumber, facilityAddress, facilityCity, facilityZipCode, facilityState, county, displayFacilityPhoneNumber, displayFacilityAddress, displayFacilityCity, displayFacilityZipCode, displayFacilityState, displayWebsite, displayEmail, facilityType, spanish, disability, openStatusIdDescription, isSubsidy, isStepUpToQuality, infantAvailability, toddlerAvailability, preschoolAvailability, schoolAgeAvailability, locationLat, locationLon, isRegistered, isApplicationPhoneNumberCellPhone, isRemoteLearningAvailable, agesFrom, agesTo, capacity, sutqRating, insertedDHHSDate, infantCapacity, toddlerCapacity, preschoolCapacity, schoolAgeCapacity, providerCapacity, status, registrationDate, providerDataSource, primaryUser, primaryUserLastLogin,  additionalUser1, additionalUser1LastLogin, additionalUser2, additionalUser2LastLogin,id, mondayHours, tuesdayHours, wednesdayHours, thursdayHours, fridayHours, saturdayHours, sundayHours }) =>
          ({ facilityName, contactName, facilityLicenseNumber, facilityEmail, facilityAltEmail, facilityPhoneNumber, facilityAddress, facilityCity, facilityZipCode, facilityState, county, displayFacilityPhoneNumber, displayFacilityAddress, displayFacilityCity, displayFacilityZipCode, displayFacilityState, displayWebsite, displayEmail, facilityType, spanish, disability, openStatusIdDescription, isSubsidy, isStepUpToQuality, infantAvailability, toddlerAvailability, preschoolAvailability, schoolAgeAvailability, locationLat, locationLon, isRegistered, isApplicationPhoneNumberCellPhone, isRemoteLearningAvailable, agesFrom, agesTo, capacity, sutqRating, insertedDHHSDate, infantCapacity, toddlerCapacity, preschoolCapacity, schoolAgeCapacity, providerCapacity, status, registrationDate, providerDataSource, primaryUser, primaryUserLastLogin,  additionalUser1, additionalUser1LastLogin, additionalUser2, additionalUser2LastLogin, id,mondayHours, tuesdayHours, wednesdayHours, thursdayHours, fridayHours, saturdayHours, sundayHours }))(this.childCaresExcel[i]);
        //const excel2 = (({ facilityName, contactName, facilityLicenseNumber, facilityEmail, facilityAltEmail, facilityPhoneNumber, facilityAddress, facilityCity, facilityZipCode, facilityState, displayFacilityPhoneNumber, displayFacilityAddress, displayFacilityCity, displayFacilityZipCode, displayFacilityState, displayWebsite, displayEmail, facilityType, spanish, disability, isOpen, isSubsidy, isStepUpToQuality, infantAvailableCapacity, toddlerAvailableCapacity, preschoolAvailableCapacity, schoolAgeAvailableCapacity, locationLat, locationLon, isRegistered, isApplicationPhoneNumberCellPhone, isRemoteLearningAvailable, agesFrom, agesTo, capacity, sutqRating, mondayHours, tuesdayHours, wednesdayHours, thursdayHours, fridayHours, saturdayHours, sundayHours, standarizedHours }) => ({ facilityName, contactName, facilityLicenseNumber, facilityEmail, facilityAltEmail, facilityPhoneNumber, facilityAddress, facilityCity, facilityZipCode, facilityState, displayFacilityPhoneNumber, displayFacilityAddress, displayFacilityCity, displayFacilityZipCode, displayFacilityState, displayWebsite, displayEmail, facilityType, spanish, disability, isOpen, isSubsidy, isStepUpToQuality, infantAvailableCapacity, toddlerAvailableCapacity, preschoolAvailableCapacity, schoolAgeAvailableCapacity, locationLat, locationLon, isRegistered, isApplicationPhoneNumberCellPhone, isRemoteLearningAvailable, agesFrom, agesTo, capacity, sutqRating, mondayHours, tuesdayHours, wednesdayHours, thursdayHours, fridayHours, saturdayHours, sundayHours, standarizedHours }))(this.childCaresExcel[i]);
        if (this.childCaresExcel[i].standarizedHours) {
          delete this.childCaresExcel[i].standarizedHours.childCareProviders;
          delete this.childCaresExcel[i].standarizedHours.providerId;
        }
        const joined = { ...excel, ...this.childCaresExcel[i].standarizedHours };  //...excel.standarizedHours };
        this.excelList.push(joined)//excel.standarizedHours);
       // this.excelList.push(excel);

      }

      new ngxCsv(this.excelList, "ProviderList", this.options);

    });


  }

  forceLogin(provider: ChildCareProvider) {
    if (provider.isRegistered && provider.status == 1) {
      this.userService.getChildCareRegisteredUser(provider.id).subscribe(result => {

        if (!result) {
          //no primary user found
          //call editunregistered user method
          this.editProviderNotRegistered(provider);
        }
        else {
          //Backup admin info
          localStorage.setItem("adminUser", JSON.stringify(this.authService.currentUserValue));

          //Get provider's user info and set it    
          this.authService.setCurrentUser(result);
          this.authService.getChildCaresWhitInitChildCare(result.id, provider);
          //this.authService.getChildCareByLicense(provider.facilityLicenseNumber);        
          // this.authService.getChildCareByLicense2(provider.facilityLicenseNumber);

          //this.authService.setCurrentChildCareValue(provider);
          this.authService.setImpersonation(true);
          localStorage.setItem("currentUser", JSON.stringify(result));
          localStorage.setItem("currentProvider", JSON.stringify(provider));
          localStorage.setItem("isImpersonation", "true");

          this.router.navigate(['profile']);
        }
      });
    }
    else {
      this.toastr.error("This provider is not registered.");
    }
  }

  editProviderNotRegistered(provider: ChildCareProvider) {
    
      //this.userService.getChildCareRegisteredUser(provider.id).subscribe(result => {
        //Backup admin info
        localStorage.setItem("adminUser", JSON.stringify(this.authService.currentUserValue));

        //Get provider's user info and set it    
       // this.authService.setCurrentUser(result);
      //this.authService.getChildCaresWhitInitChildCare(result.id, provider);
    //this.authService.getProfileInfo(provider.id, provider);
    this.authService.getProfileInfoAsEditing(provider.id, provider);
       // this.authService.setImpersonation(true);
        //localStorage.setItem("currentUser", JSON.stringify(result));
        localStorage.setItem("currentProvider", JSON.stringify(provider));
        localStorage.setItem("isImpersonation", "true");
      localStorage.setItem("isAdminEditing", "true");
      this.authService.setIsAdminEditing(true);

        this.router.navigate(['profile']);

      //});
      
     // this.router.navigate(['profile']);
    
    
  }


  showForceRegistrationModal(provider: ChildCareProvider) {
    if (provider.isRegistered) this.toastr.error("This provider is already registered.");
    else {
      this.selectedChildCare = provider;
      ($('#forceSubscription') as any).modal('show');
    }
  }
  //showUserManagementModal(provider: ChildCareProvider) {
  //  console.log('provideRId:', provider.id);
  //  if (provider.id) {
  //    console.log('inside');      
  //    //($('#userManagementModal') as any).modal('show');
  //  }

  //}

  forceRegistration(provider: ChildCareProvider) {
    if (this.registerOptionId == 1) {
      if (this.validateApplicationNumber(provider.applicationPhoneNumber)) {
        //Verify if the user have application number, if not show modal
        if (provider.isApplicationPhoneNumberCellPhone == null) provider.isApplicationPhoneNumberCellPhone = false;
        this.capacityRequestService.forceRegistration(provider, 1).subscribe(result => {
          if (result.result === "success") {
            ($('#forceSubscription') as any).modal('hide');
            //Backup admin info
            provider.isRegistered = true;
            localStorage.setItem("adminUser", JSON.stringify(this.userService.currentUser));
            this.forceLogin(provider);
          }
          else {
            this.toastr.error(result.message);
          }
        });
      }
      else {
        this.toastr.error("Application phone number must have 10 digits and must contain only numbers.");
      }
    }
    else {
      //Register with email here
      this.capacityRequestService.forceRegistration(provider, 2).subscribe(result => {
        if (result.result === "success") {
          ($('#forceSubscription') as any).modal('hide');
          //Backup admin info
          localStorage.setItem("adminUser", JSON.stringify(this.userService.currentUser));
          provider.isRegistered = true;
          this.forceLogin(provider);
        }
        else {
          this.toastr.error(result.message);
        }
      });
    }
  }

  validateApplicationNumber(appNumber: string): boolean {
    if (appNumber) {
      let isnum = /^\d+$/.test(appNumber);
      if (appNumber.length > 0 && appNumber.length == 10 && isnum) return true
      else return false;
    }
    return false;
  }

  handlePhonetype(provider: ChildCareProvider) {
    this.selectedChildCare = provider;
    this.saveChanges();
  }

  onOptionsSelected(value) {
    //show option selected
    this.registerOptionId = value;
  }
  onChangeStatus(id) {
    this.selectedMessage.isHidden = !this.selectedMessage.isHidden;
    //console.log(this.selectedMessage)

  }

  searchByCapacity(event): void {
    debugger;
    // Check capacity checkbox to  refresh the filter value
    if (event.target.value === "1") { this.filter.infatnt = event.target.checked; }
    if (event.target.value === "2") { this.filter.toddler = event.target.checked; }
    if (event.target.value === "3") { this.filter.preschool = event.target.checked; }
    if (event.target.value === "4") { this.filter.schoolAge = event.target.checked; }

    //if (this.filter.infatnt || this.filter.toddler || this.filter.preschool || this.filter.schoolAge) this.isAgeSearch = true;
    //else this.isAgeSearch = false;
  }
  getOpenStatusDescription(childCare: ChildCareProvider) {

    if (childCare.openStatusId == 0) return 'Closed';
    else if (childCare.openStatusId == 1) return 'Open';
    else if (childCare.openStatusId == 2) return 'Closed but accepting applications';

  }
}
