import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { RouterModule, Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { LoginComponent } from './shared/login/login.component';
import { AuthGuard } from './service/auth/auth-guard.service';
import { MainComponent } from './home/main/main.component';
import { RegisterComponent } from './home/register/register.component';
import { ProfileComponent } from './home/profile/profile.component';
import { HomeComponent } from './home/home.component';
import { NavMenuComponent } from './shared/nav-menu/nav-menu.component';
import { FooterComponent } from './shared/footer/footer.component';
import { SetPasswordComponent } from './home/set-password/set-password.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { ToastrModule } from 'ngx-toastr';
import { GoogleMapsModule } from '@angular/google-maps'
import { NumericDirective } from './shared/numeric.directive';
import { ResetPasswordComponent } from './home/reset-password/reset-password.component';
import { CookieService } from 'ngx-cookie-service';
import { AdminProvidersComponent } from './home/admin/admin-providers.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FileUploadModule } from 'ng2-file-upload';
import { ImageCropperModule } from 'ngx-image-cropper';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgbModule, NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { ProviderMessagesComponent } from './home/provider-messages/provider-messages.component';
import { QuillModule } from 'ngx-quill';

import { IConfig, NgxMaskModule } from 'ngx-mask';
import { SetAvailabilityComponent } from './home/availability/set-availability.component';
import { SurveyComponent } from './home/survey/survey.component';
import { PrimaryEmailMgmtComponent } from './components/primary-email-mgmt/primary-email-mgmt.component';
import { AdditionalUserComponent } from './components/additional-user/additional-user.component';
import { JwtModule } from '@auth0/angular-jwt';

const routes: Routes = [
  {
    path: '', component: HomeComponent, children: [
      { path: '', component: MainComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'survey', component: SurveyComponent },
      { path: 'login', component: LoginComponent },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthGuard] },
      { path: 'set-password/:token', component: SetPasswordComponent },
      { path: 'reset-password/:auto', component: ResetPasswordComponent },
      { path: 'admin-providers', component: AdminProvidersComponent, canActivate: [AuthGuard] },
      { path: 'profile/:id', component: ProfileComponent },
      { path: 'providers-messages', component: ProviderMessagesComponent, canActivate: [AuthGuard] },
      { path: 'primary-email', component: PrimaryEmailMgmtComponent, canActivate: [AuthGuard] }
      
    ],

  },
  { path: 'set-availability/:token', component: SetAvailabilityComponent },
  { path: 'primary-email/:providerid', component: PrimaryEmailMgmtComponent, canActivate: [AuthGuard] },  
  { path: '**', redirectTo: '' }
];

export const options: Partial<IConfig> | (() => Partial<IConfig>) = null;
const maskConfig: Partial<IConfig> = {
  validation: false,
};

export function tokenGetter() {
  return localStorage.getItem("jwt");
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    MainComponent,
    RegisterComponent,
    ProfileComponent,
    NavMenuComponent,
    FooterComponent,
    LoginComponent,
    SetPasswordComponent,
    ResetPasswordComponent,
    NumericDirective,
    AdminProvidersComponent,
    ProviderMessagesComponent,
    SurveyComponent,
    SetAvailabilityComponent,
    PrimaryEmailMgmtComponent,
    AdditionalUserComponent
  ],
  imports: [
    //Fix modules issue when using routing
    BrowserModule.withServerTransition({ appId: 'ng-cli-universal' }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    FileUploadModule,
    ImageCropperModule,
    GoogleMapsModule,
    GooglePlaceModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        whitelistedDomains: ["localhost:44368", "dev-nebraskachildcare.azurewebsites.net", "stage-nebraskachildcare.azurewebsites.net","www.nechildcarereferral.org"],
        //disallowedRoutes: []
      }
    }),
    NgxMaskModule.forRoot(),    
    NgbModule, NgbDropdownModule,
    RouterModule.forRoot(routes, {}),
    ToastrModule.forRoot(),
    QuillModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (http: HttpClient) => {
          return new TranslateHttpLoader(http);
        },
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    CookieService
  ],
  bootstrap: [AppComponent],//, AdminProvidersComponent],
  entryComponents: [PrimaryEmailMgmtComponent, AdminProvidersComponent]
  //exports: [AdminProvidersComponent]
})
export class AppModule { }
