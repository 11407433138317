import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Component({
    selector: 'app-survey',
    templateUrl: './survey.component.html',
    styleUrls: ['./survey.component.scss']
})

export class SurveyComponent {

  constructor(private _renderer2: Renderer2,@Inject(DOCUMENT) private _document: Document) {

  }

 //public ngOnInit() {

 //   const script = this._renderer2.createElement('script');
 //   script.type = `text/javascript`;
 //   script.text = `
 //    (function(t,e,s,n){var o,a,c;t.SMCX=t.SMCX||[],e.getElementById(n)||(o=e.getElementsByTagName(s),a=o[o.length-1],c=e.createElement(s),c.type="text/javascript",c.async=!0,c.id=n,c.src='https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd5wFHAggLoC_2FPDGOyzX1B_2Fdzd1ZgvAgZR4U_2FjbS4F8aZ.js',a.parentNode.insertBefore(c,a))})(window,document,"script","smcx-sdk");
 //       `;

 //  this._renderer2.appendChild(this._document.activeElement, script);
 // }

  ngAfterViewInit() {
    var script = document.createElement('script');
    script.src = "https://widget.surveymonkey.com/collect/website/js/tRaiETqnLgj758hTBazgd5wFHAggLoC_2FPDGOyzX1B_2Fdzd1ZgvAgZR4U_2FjbS4F8aZ.js";
    
    document.head.innerHTML += '<link rel="stylesheet" href="assets/css/embed.css" type="text/css"/>';
    document.getElementById("smcx-sdk").appendChild(script);
   
    

  }
}
