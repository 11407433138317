import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ChildCareProvider } from 'src/app/models/child-care-provider';
import { AuthenticationService, User } from 'src/app/auth/authentication.service';
import { CapacityRequestService } from 'src/app/service/capacity-request.service';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/service/user.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GoogleMap } from '@angular/google-maps';
import { ActivatedRoute, Router } from '@angular/router';
import { AddressComponent } from 'ngx-google-places-autocomplete/objects/addressComponent';
import { ProviderReminderSettings } from 'src/app/models/provider-reminder-settings';
import { ProviderReminderSettingsService } from '../../service/provider-reminder-settings.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit, AfterViewInit {
  @ViewChild("placesRef") placesRef : GooglePlaceDirective;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  //@ViewChild('exampleModal') exampleModalRef!: ElementRef;
  //exampleModal!: Modal;


  childCare: ChildCareProvider;
  providerReminderSettings: ProviderReminderSettings;
  currentUser: User;  
  mondayS: any;
  mondayE: any;
  tuesdayE: any;
  tuesdayS: any;
  wednesdayS: any;
  wednesdayE: any;
  thursdayS: any;
  thursdayE: any;
  fridayS: any;
  fridayE: any;
  saturdayS: any;
  saturdayE: any;
  sundayS: any;
  sundayE: any;

  //variables to display on left side
  mondaySdisplay: string;
  mondayEdisplay: string;
  tuesdayEdisplay: string;
  tuesdaySdisplay: string;
  wednesdaySdisplay: string;
  wednesdayEdisplay: string;
  thursdaySdisplay: string;
  thursdayEdisplay: string;
  fridaySdisplay: string;
  fridayEdisplay: string;
  saturdaySdisplay: string;
  saturdayEdisplay: string;
  sundaySdisplay: string;
  sundayEdisplay: string;

  url: any;
  license: string;
  imageChangedEvent: any = '';
  croppedImage: any = '';
  editImage= false;
  activeLang = 'en';
  isImpersonation = false;
  isTransportationProvided = false;
  isAdditionalLanguage = false;
  isWheelchairAccessible = false;
  foodAllergiesNeeds = false;
  isPrimaryUser: boolean = false;
  isParentingClasses = false;
  isEngagementOpportunities = false;
  isConnectionToServices = false;
  isProvideRespiteCare = false;
  isDropInCare = false;
  // Map attributes
  zoom = 14;
  center = {
    lat: 41.24,
    lng: -96.06,
  }
  Mapoptions: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: false,
    mapTypeId: 'roadmap',
    maxZoom: 25,
    minZoom: 8,
    disableDefaultUI: false,
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off'}]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [{visibility: 'off'}]
      },
    ],
  }
  markers = []
  options = {
    types: [],
    componentRestrictions: {
      country: 'us'
    }
  };

  
  selectedOption = 1;
  token: string;

  tConvert(time: any) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  constructor(
    private authService: AuthenticationService,
    private childCareService: CapacityRequestService,
    private userService: UserService,
    private toastr: ToastrService,
    public router: Router,
    //private modalService: ModalService,    
    private reminderService: ProviderReminderSettingsService,
    private route: ActivatedRoute)
    {
    this.childCare = new ChildCareProvider();
    
    //simulate login by remainder link
    this.token = this.route.snapshot.paramMap.get("id");
    //console.log(this.token);
    if (this.token) {

      this.authService.getProfileInfoByProviderId(this.token).subscribe(
        res => {
          this.childCare = res;
          this.mondayS = this.childCare?.standarizedHours?.mondayStart;
          this.mondayE = this.childCare?.standarizedHours?.mondayEnd;
          this.tuesdayS = this.childCare?.standarizedHours?.tuesdayStart;
          this.tuesdayE = this.childCare?.standarizedHours?.tuesdayEnd;
          this.wednesdayS = this.childCare?.standarizedHours?.wednesdayStart;
          this.wednesdayE = this.childCare?.standarizedHours?.wednesdayEnd;
          this.thursdayS = this.childCare?.standarizedHours?.thursdayStart;
          this.thursdayE = this.childCare?.standarizedHours?.thursdayEnd;
          this.fridayS = this.childCare?.standarizedHours?.fridayStart;
          this.fridayE = this.childCare?.standarizedHours?.fridayEnd;
          this.saturdayS = this.childCare?.standarizedHours?.saturdayStart;
          this.saturdayE = this.childCare?.standarizedHours?.saturdayEnd;
          this.sundayS = this.childCare?.standarizedHours?.sundayStart;
          this.sundayE = this.childCare?.standarizedHours?.sundayEnd;
          //convert to am/pm
          this.mondaySdisplay = this.childCare.standarizedHours?.mondayStart ? this.tConvert(this.childCare.standarizedHours.mondayStart?.slice(0, 5)) : 'Closed';
          this.mondayEdisplay = this.childCare.standarizedHours?.mondayEnd ? this.tConvert(this.childCare.standarizedHours.mondayEnd?.slice(0, 5)) : 'Closed';
          this.tuesdaySdisplay = this.childCare.standarizedHours?.tuesdayStart ? this.tConvert(this.childCare.standarizedHours.tuesdayStart?.slice(0, 5)) : 'Closed';
          this.tuesdayEdisplay = this.childCare.standarizedHours?.tuesdayEnd ? this.tConvert(this.childCare.standarizedHours.tuesdayEnd?.slice(0, 5)) : 'Closed';
          this.wednesdaySdisplay = this.childCare.standarizedHours?.wednesdayStart ? this.tConvert(this.childCare.standarizedHours.wednesdayStart?.slice(0, 5)) : 'Closed';
          this.wednesdayEdisplay = this.childCare.standarizedHours?.wednesdayEnd ? this.tConvert(this.childCare.standarizedHours.wednesdayEnd?.slice(0, 5)) : 'Closed';
          this.thursdaySdisplay = this.childCare.standarizedHours?.thursdayStart ? this.tConvert(this.childCare.standarizedHours.thursdayStart?.slice(0, 5)) : 'Closed';
          this.thursdayEdisplay = this.childCare.standarizedHours?.thursdayEnd ? this.tConvert(this.childCare.standarizedHours.thursdayEnd?.slice(0, 5)) : 'Closed';
          this.fridaySdisplay = this.childCare.standarizedHours?.fridayStart ? this.tConvert(this.childCare.standarizedHours.fridayStart?.slice(0, 5)) : 'Closed';
          this.fridayEdisplay = this.childCare.standarizedHours?.fridayEnd ? this.tConvert(this.childCare.standarizedHours.fridayEnd?.slice(0, 5)) : 'Closed';
          this.saturdaySdisplay = this.childCare.standarizedHours?.saturdayStart ? this.tConvert(this.childCare.standarizedHours.saturdayStart?.slice(0, 5)) : 'Closed';
          this.saturdayEdisplay = this.childCare.standarizedHours?.saturdayEnd ? this.tConvert(this.childCare.standarizedHours.saturdayEnd?.slice(0, 5)) : 'Closed';
          this.sundaySdisplay = this.childCare.standarizedHours?.sundayStart ? this.tConvert(this.childCare.standarizedHours.sundayStart?.slice(0, 5)) : 'Closed';
          this.sundayEdisplay = this.childCare.standarizedHours?.sundayEnd ? this.tConvert(this.childCare.standarizedHours.sundayEnd?.slice(0, 5)) : 'Closed';

          // truncate seconds from input time
          if (this.childCare.standarizedHours) {
            this.childCare.standarizedHours.mondayStart = this.childCare.standarizedHours?.mondayStart?.slice(0, 5);
            this.childCare.standarizedHours.mondayEnd = this.childCare.standarizedHours?.mondayEnd?.slice(0, 5);
            this.childCare.standarizedHours.tuesdayStart = this.childCare.standarizedHours?.tuesdayStart?.slice(0, 5);
            this.childCare.standarizedHours.tuesdayEnd = this.childCare.standarizedHours?.tuesdayEnd?.slice(0, 5);
            this.childCare.standarizedHours.wednesdayStart = this.childCare.standarizedHours?.wednesdayStart?.slice(0, 5);
            this.childCare.standarizedHours.wednesdayEnd = this.childCare.standarizedHours?.wednesdayEnd?.slice(0, 5);
            this.childCare.standarizedHours.thursdayStart = this.childCare.standarizedHours?.thursdayStart?.slice(0, 5);
            this.childCare.standarizedHours.thursdayEnd = this.childCare.standarizedHours?.thursdayEnd?.slice(0, 5);
            this.childCare.standarizedHours.fridayStart = this.childCare.standarizedHours?.fridayStart?.slice(0, 5);
            this.childCare.standarizedHours.fridayEnd = this.childCare.standarizedHours?.fridayEnd?.slice(0, 5);
            this.childCare.standarizedHours.saturdayStart = this.childCare.standarizedHours?.saturdayStart?.slice(0, 5);
            this.childCare.standarizedHours.saturdayEnd = this.childCare.standarizedHours?.saturdayEnd?.slice(0, 5);
            this.childCare.standarizedHours.sundayStart = this.childCare.standarizedHours?.sundayStart?.slice(0, 5);
            this.childCare.standarizedHours.sundayEnd = this.childCare.standarizedHours?.sundayEnd?.slice(0, 5);


            if (this.mondaySdisplay === 'Closed' || this.mondayEdisplay === 'Closed')
              this.childCare.standarizedHours.mIsCLosed = true;
            if (this.tuesdaySdisplay === 'Closed' || this.tuesdayEdisplay === 'Closed')
              this.childCare.standarizedHours.tIsCLosed = true;
            if (this.wednesdaySdisplay === 'Closed' || this.wednesdayEdisplay === 'Closed')
              this.childCare.standarizedHours.wIsCLosed = true;
            if (this.thursdaySdisplay === 'Closed' || this.thursdayEdisplay === 'Closed')
              this.childCare.standarizedHours.thIsCLosed = true;
            if (this.fridaySdisplay === 'Closed' || this.fridayEdisplay === 'Closed')
              this.childCare.standarizedHours.fIsCLosed = true;
            if (this.saturdaySdisplay === 'Closed' || this.saturdayEdisplay === 'Closed')
              this.childCare.standarizedHours.sIsCLosed = true;
            if (this.sundaySdisplay === 'Closed' || this.sundayEdisplay === 'Closed')
              this.childCare.standarizedHours.suIsCLosed = true;
          }
          this.isTransportationProvided = this.childCare?.aditionalFilters?.isTransportationProvided;
          this.isAdditionalLanguage = this.childCare?.aditionalFilters?.isAdditionalLanguage;
          this.isWheelchairAccessible = this.childCare?.aditionalFilters?.isWheelchairAccessible;
          this.foodAllergiesNeeds = this.childCare?.aditionalFilters?.foodAllergiesNeeds;
          this.isParentingClasses = this.childCare?.aditionalFilters?.isParentingClasses;
          this.isEngagementOpportunities = this.childCare?.aditionalFilters?.isEngagementOpportunities;
          this.isConnectionToServices = this.childCare?.aditionalFilters?.isConnectionToServices;
          this.isProvideRespiteCare = this.childCare?.aditionalFilters?.isProvideRespiteCare;
          this.isDropInCare = this.childCare?.aditionalFilters?.isDropInCare;          
          
          if (this.isTransportationProvided === undefined) {
            this.isTransportationProvided = false;
            //this.isParentingClasses = false
            this.childCare.aditionalFilters = { isTransportationProvided: false, transportationDetails: '', nextInfantSlotAvailable: null, isWheelchairAccessible: false, foodAllergiesNeeds: false, isAdditionalLanguage: false, additionalLanguageDetails: '', isParentingClasses: false, isEngagementOpportunities: false, isConnectionToServices: false, isProvideRespiteCare: false, isDropInCare: false };
          }
        }
      )

    }

    else {// login flow, impersonation and Edit provider

      this.currentUser = this.authService.currentUserValue;
      this.userService.currentUser = this.authService.currentUserValue;
      this.providerReminderSettings = new ProviderReminderSettings();
      //console.log('currentuser:', this.currentUser);
      

      if (localStorage.getItem("activeLang"))
        this.activeLang = localStorage.getItem("activeLang");

      if (localStorage.getItem("currentProvider"))
        this.authService.setCurrentChildCareValue(JSON.parse(localStorage.getItem("currentProvider")));
      if (localStorage.getItem("isImpersonation")) {
        this.isImpersonation = true;
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
      }
            
      //validate if provider is unregistered only request profile info by providerID instead UserId
      if (this.userService.currentUser?.userType === 1)
        this.authService.getChildCares(this.currentUser.id);// force reload when page is refreshed

      this.authService.getCurrentChildCareObservable().subscribe(result => {

        this.childCare = this.authService.getCurrentChildCare();
        if (this.childCare) {

          //when provider is selected on drop down list
          //validate if additional user is primary
          const usersProviders = this.authService.currentUserValue as any;      
          this.isPrimaryUser = usersProviders?.userProviders?.filter((user) => {
            return (user.providerId === this.childCare.id && user.providerUserTypeId === 1);
            
          }).length>0;
          //console.log('this.childCare', this.childCare);
         // console.log('isprimary', this.isPrimaryUser);

          this.mondayS = this.childCare?.standarizedHours?.mondayStart;
          this.mondayE = this.childCare?.standarizedHours?.mondayEnd;
          this.tuesdayS = this.childCare?.standarizedHours?.tuesdayStart;
          this.tuesdayE = this.childCare?.standarizedHours?.tuesdayEnd;
          this.wednesdayS = this.childCare?.standarizedHours?.wednesdayStart;
          this.wednesdayE = this.childCare?.standarizedHours?.wednesdayEnd;
          this.thursdayS = this.childCare?.standarizedHours?.thursdayStart;
          this.thursdayE = this.childCare?.standarizedHours?.thursdayEnd;
          this.fridayS = this.childCare?.standarizedHours?.fridayStart;
          this.fridayE = this.childCare?.standarizedHours?.fridayEnd;
          this.saturdayS = this.childCare?.standarizedHours?.saturdayStart;
          this.saturdayE = this.childCare?.standarizedHours?.saturdayEnd;
          this.sundayS = this.childCare?.standarizedHours?.sundayStart;
          this.sundayE = this.childCare?.standarizedHours?.sundayEnd;
          //convert to am/pm
          this.mondaySdisplay = this.childCare.standarizedHours?.mondayStart ? this.tConvert(this.childCare.standarizedHours.mondayStart?.slice(0, 5)) : 'Closed';
          this.mondayEdisplay = this.childCare.standarizedHours?.mondayEnd ? this.tConvert(this.childCare.standarizedHours.mondayEnd?.slice(0, 5)) : 'Closed';
          this.tuesdaySdisplay = this.childCare.standarizedHours?.tuesdayStart ? this.tConvert(this.childCare.standarizedHours.tuesdayStart?.slice(0, 5)) : 'Closed';
          this.tuesdayEdisplay = this.childCare.standarizedHours?.tuesdayEnd ? this.tConvert(this.childCare.standarizedHours.tuesdayEnd?.slice(0, 5)) : 'Closed';
          this.wednesdaySdisplay = this.childCare.standarizedHours?.wednesdayStart ? this.tConvert(this.childCare.standarizedHours.wednesdayStart?.slice(0, 5)) : 'Closed';
          this.wednesdayEdisplay = this.childCare.standarizedHours?.wednesdayEnd ? this.tConvert(this.childCare.standarizedHours.wednesdayEnd?.slice(0, 5)) : 'Closed';
          this.thursdaySdisplay = this.childCare.standarizedHours?.thursdayStart ? this.tConvert(this.childCare.standarizedHours.thursdayStart?.slice(0, 5)) : 'Closed';
          this.thursdayEdisplay = this.childCare.standarizedHours?.thursdayEnd ? this.tConvert(this.childCare.standarizedHours.thursdayEnd?.slice(0, 5)) : 'Closed';
          this.fridaySdisplay = this.childCare.standarizedHours?.fridayStart ? this.tConvert(this.childCare.standarizedHours.fridayStart?.slice(0, 5)) : 'Closed';
          this.fridayEdisplay = this.childCare.standarizedHours?.fridayEnd ? this.tConvert(this.childCare.standarizedHours.fridayEnd?.slice(0, 5)) : 'Closed';
          this.saturdaySdisplay = this.childCare.standarizedHours?.saturdayStart ? this.tConvert(this.childCare.standarizedHours.saturdayStart?.slice(0, 5)) : 'Closed';
          this.saturdayEdisplay = this.childCare.standarizedHours?.saturdayEnd ? this.tConvert(this.childCare.standarizedHours.saturdayEnd?.slice(0, 5)) : 'Closed';
          this.sundaySdisplay = this.childCare.standarizedHours?.sundayStart ? this.tConvert(this.childCare.standarizedHours.sundayStart?.slice(0, 5)) : 'Closed';
          this.sundayEdisplay = this.childCare.standarizedHours?.sundayEnd ? this.tConvert(this.childCare.standarizedHours.sundayEnd?.slice(0, 5)) : 'Closed';

          // truncate seconds from input time
          if (this.childCare.standarizedHours) {
            this.childCare.standarizedHours.mondayStart = this.childCare.standarizedHours?.mondayStart?.slice(0, 5);
            this.childCare.standarizedHours.mondayEnd = this.childCare.standarizedHours?.mondayEnd?.slice(0, 5);
            this.childCare.standarizedHours.tuesdayStart = this.childCare.standarizedHours?.tuesdayStart?.slice(0, 5);
            this.childCare.standarizedHours.tuesdayEnd = this.childCare.standarizedHours?.tuesdayEnd?.slice(0, 5);
            this.childCare.standarizedHours.wednesdayStart = this.childCare.standarizedHours?.wednesdayStart?.slice(0, 5);
            this.childCare.standarizedHours.wednesdayEnd = this.childCare.standarizedHours?.wednesdayEnd?.slice(0, 5);
            this.childCare.standarizedHours.thursdayStart = this.childCare.standarizedHours?.thursdayStart?.slice(0, 5);
            this.childCare.standarizedHours.thursdayEnd = this.childCare.standarizedHours?.thursdayEnd?.slice(0, 5);
            this.childCare.standarizedHours.fridayStart = this.childCare.standarizedHours?.fridayStart?.slice(0, 5);
            this.childCare.standarizedHours.fridayEnd = this.childCare.standarizedHours?.fridayEnd?.slice(0, 5);
            this.childCare.standarizedHours.saturdayStart = this.childCare.standarizedHours?.saturdayStart?.slice(0, 5);
            this.childCare.standarizedHours.saturdayEnd = this.childCare.standarizedHours?.saturdayEnd?.slice(0, 5);
            this.childCare.standarizedHours.sundayStart = this.childCare.standarizedHours?.sundayStart?.slice(0, 5);
            this.childCare.standarizedHours.sundayEnd = this.childCare.standarizedHours?.sundayEnd?.slice(0, 5);

            if (this.mondaySdisplay === 'Closed' || this.mondayEdisplay === 'Closed')
              this.childCare.standarizedHours.mIsCLosed = true;
            if (this.tuesdaySdisplay === 'Closed' || this.tuesdayEdisplay === 'Closed')
              this.childCare.standarizedHours.tIsCLosed = true;
            if (this.wednesdaySdisplay === 'Closed' || this.wednesdayEdisplay === 'Closed')
              this.childCare.standarizedHours.wIsCLosed = true;
            if (this.thursdaySdisplay === 'Closed' || this.thursdayEdisplay === 'Closed')
              this.childCare.standarizedHours.thIsCLosed = true;
            if (this.fridaySdisplay === 'Closed' || this.fridayEdisplay === 'Closed')
              this.childCare.standarizedHours.fIsCLosed = true;
            if (this.saturdaySdisplay === 'Closed' || this.saturdayEdisplay === 'Closed')
              this.childCare.standarizedHours.sIsCLosed = true;
            if (this.sundaySdisplay === 'Closed' || this.sundayEdisplay === 'Closed')
              this.childCare.standarizedHours.suIsCLosed = true;
          }

          this.isTransportationProvided = this.childCare?.aditionalFilters?.isTransportationProvided;
          this.isAdditionalLanguage = this.childCare?.aditionalFilters?.isAdditionalLanguage;
          this.isWheelchairAccessible = this.childCare?.aditionalFilters?.isWheelchairAccessible;
          this.foodAllergiesNeeds = this.childCare?.aditionalFilters?.foodAllergiesNeeds;
          this.isParentingClasses = this.childCare?.aditionalFilters?.isParentingClasses;
          this.isEngagementOpportunities = this.childCare?.aditionalFilters?.isEngagementOpportunities;
          this.isConnectionToServices = this.childCare?.aditionalFilters?.isConnectionToServices;
          this.isProvideRespiteCare = this.childCare?.aditionalFilters?.isProvideRespiteCare;
          this.isDropInCare = this.childCare?.aditionalFilters?.isDropInCare
          if (this.childCare?.aditionalFilters === undefined) {
            //this.isTransportationProvided = false;
            //this.isParentingClasses = false;
            this.childCare.aditionalFilters = { isTransportationProvided: false, transportationDetails: '', nextInfantSlotAvailable: null, isWheelchairAccessible: false, foodAllergiesNeeds: false, isAdditionalLanguage: false, additionalLanguageDetails: '', isParentingClasses: false, isEngagementOpportunities: false, isConnectionToServices: false, isProvideRespiteCare: false, isDropInCare: false };
          }

        }

        if (!this.childCare) this.router.navigate(['']);
        this.croppedImage = (this.childCare?.profileImageName) ? this.childCare?.profileImageName : "images/listing/default.jpg";
        //console.log('ctr', this.croppedImage)        
        this.addMarker(this.childCare);
        this.center = {
          lat: +this.childCare?.locationLat,
          lng: +this.childCare?.locationLon,
        }
      });

    }

  }
  ngAfterViewInit(): void {
    //replace for native boostrap
    ($("[data-toggle=popover]") as any).popover();
    const withNgIf = document.getElementById('p2');
            
  }

  ngOnInit() {
  }


  toggleIsTransportationProvided() {
    this.isTransportationProvided = !this.isTransportationProvided;
   
    if (!this.isTransportationProvided) {
      this.childCare.aditionalFilters.transportationDetails = "";
      this.updateServiceCall(9);
      
    }
  }

  toggleIsAdditionalLanguage() {
    this.isAdditionalLanguage = !this.isAdditionalLanguage;

    if (!this.isAdditionalLanguage) {
      this.childCare.aditionalFilters.additionalLanguageDetails = "";
      this.updateServiceCall(9);

    }
  }

  toggleIsWheelchairAccessible() {
    this.childCare.aditionalFilters.isWheelchairAccessible = !this.isWheelchairAccessible;
      this.updateServiceCall(11);    
    
  }

  toggleIsParentingClasses() {
    this.childCare.aditionalFilters.isParentingClasses = !this.isParentingClasses;   
    this.updateServiceCall(15);    
  }

  toggleIsEngagementOpportunitiesCheck() {
    this.childCare.aditionalFilters.isEngagementOpportunities = !this.isEngagementOpportunities;
    this.updateServiceCall(16);
  }

  toggleIsConnectionToServicesCheck() {
    this.childCare.aditionalFilters.isConnectionToServices = !this.isConnectionToServices;
    this.updateServiceCall(17);
  }

  toggleIsProvideRespiteCareCheck() {
    this.childCare.aditionalFilters.isProvideRespiteCare = !this.isProvideRespiteCare;
    this.updateServiceCall(18);
  }

  toggleIsDropInCareCheck() {
    this.childCare.aditionalFilters.isDropInCare = !this.isDropInCare;
    this.updateServiceCall(19);
  }
  toggleFoodAllergiesNeeds() {
    this.childCare.aditionalFilters.foodAllergiesNeeds = !this.childCare.aditionalFilters.foodAllergiesNeeds;
    this.updateServiceCall(12);
  }
  //deprecated june 29 2023
  //updateAvailability(event: any){
  //  if(this.childCare.isThereCapacity) {
  //    this.childCare.isThereCapacity = !this.childCare.isThereCapacity;
  //    this.childCare.infantAvailability = 0;
  //    this.childCare.toddlerAvailability = 0;
  //    this.childCare.preschoolAvailability = 0;
  //    this.childCare.schoolAgeAvailability = 0;
  //  }
  //  else {
  //    if (this.childCare.infantAvailability === 0 &&
  //      this.childCare.toddlerAvailability === 0 &&
  //      this.childCare.preschoolAvailability === 0 &&
  //      this.childCare.schoolAgeAvailability === 0)
  //    {
  //      //Replace for multilanguage
  //      this.toastr.warning("Please indicate the number of open spots by age group");
  //      event.target.checked = false;
  //      return;
  //    }
  //    else
  //        this.childCare.isThereCapacity = !this.childCare.isThereCapacity;
  //  }

  //  this.updateServiceCall(1);
  //}
  //deprecated
  updateAvailabilityIndicators() {
    this.updateServiceCall(2);
  }

  //updateOpenIndicator() {
  //  this.childCare.isOpen = !this.childCare.isOpen;
  //  this.updateServiceCall(3);
  //}
  updateOpenStatus(status: number):void {
    //console.log(status);
    this.childCare.openStatusId = status;
    this.updateServiceCall(3);
  }

  updateSpanishStaffIndicator() {
    this.childCare.spanish = !this.childCare.spanish;
    this.updateServiceCall(4);
  }

  updateSpecialNeedsIndicator() {
    this.childCare.disability = !this.childCare.disability;
    this.updateServiceCall(5);
  }
  updateAdditionalFilters9() {

    if (!this.childCare?.aditionalFilters?.nextInfantSlotAvailable)
      this.childCare.aditionalFilters.nextInfantSlotAvailable = null;

    this.updateServiceCall(9);
  }
  //deprecated
  updateAdditionalFilters10() {
    
    if (!this.childCare?.aditionalFilters?.nextInfantSlotAvailable)
      this.childCare.aditionalFilters.nextInfantSlotAvailable = null;

    this.updateServiceCall(10);
  }

  updateRemoteLEarners() {
    this.childCare.isRemoteLearningAvailable = !this.childCare.isRemoteLearningAvailable;
    this.updateServiceCall(6);
  }
    

  updateAvailablehours() {
    this.updateServiceCall(7);
    this.viewHideHours();
  }

  updateDisplayIndicators() {
    this.updateServiceCall(8);
    this.viewHideContactInfo();
  }
  updateAdditionalLanguages() {
    this.updateServiceCall(14);
  }


  //deprecated
  updateCapacityIndicators() {
   // console.log(this.childCare);
    this.childCare.infantCapacity = this.childCare.infantCapacity ?? 0;
    this.childCare.toddlerCapacity = this.childCare.toddlerCapacity ?? 0;
    this.childCare.preschoolCapacity = this.childCare.preschoolCapacity ?? 0;
    this.childCare.schoolAgeCapacity = this.childCare.schoolAgeCapacity ?? 0;
    this.updateServiceCall(13);
  }

  updateAvailabilityAndCapacitySingleButton() {
    // console.log(this.childCare);

    //next infant slot available
    if (!this.childCare?.aditionalFilters?.nextInfantSlotAvailable)
      this.childCare.aditionalFilters.nextInfantSlotAvailable = null;

    //availability
    //this.childCare.infantAvailability = this.childCare.infantAvailability ?? 0;
    //this.childCare.toddlerAvailability = this.childCare.toddlerAvailability ?? 0;
    //this.childCare.preschoolAvailability = this.childCare.preschoolAvailability ?? 0;
    //this.childCare.schoolAgeAvailability = this.childCare.schoolAgeAvailability ?? 0;
    //capacity
    this.childCare.infantCapacity = this.childCare.infantCapacity ?? 0;
    this.childCare.toddlerCapacity = this.childCare.toddlerCapacity ?? 0;
    this.childCare.preschoolCapacity = this.childCare.preschoolCapacity ?? 0;
    this.childCare.schoolAgeCapacity = this.childCare.schoolAgeCapacity ?? 0;

    //turn toggle ON if any slot is >0
    if (this.childCare.infantAvailability > 0 || this.childCare.toddlerAvailability > 0 || this.childCare.preschoolAvailability > 0 || this.childCare.schoolAgeAvailability > 0)
      this.childCare.isThereCapacity = true;
    //turn toggle off if all slots were set to  zero
    if (this.childCare.infantAvailability === 0 && this.childCare.toddlerAvailability === 0 && this.childCare.preschoolAvailability === 0 && this.childCare.schoolAgeAvailability === 0)
      this.childCare.isThereCapacity = false;


    this.updateServiceCall(13);
  }

  saveProfilePicture(event) {
    if (!this.croppedImage)
      //Add multilang
      this.toastr.success("You must upload an image first");
    
    this.childCareService.uploadChildCareProfileImg(this.childCare.id, this.croppedImage).subscribe(result => {
      console.log(this.childCare.profileImageName);

      this.childCare.profileImageName = result.result;

      this.authService.setCurrentChildCareValue(this.childCare);
      localStorage.setItem("currentProvider", JSON.stringify(this.childCare));

        this.editImage = false;
        if (this.activeLang === "en") {
          this.toastr.success("Changes Saved Successfully");
        }
        else {
          this.toastr.success("Cambios guardados Correctamente");
        }    
      console.log(this.childCare.profileImageName);
      
      
      //this.authService.getChildCares(this.currentUser.id);      
      //var modal = document.getElementById('myModal');
      //modal.style.display = 'none'
      //modal.classList.remove('modal-backdrop');
      //this.authService.getChildCares(this.currentUser.id);

      //this.authService.getCurrentChildCareObservable().subscribe(result => {

       // this.childCare = this.authService.getCurrentChildCare();
        //console.log(result.profileImageName);
      
      
        //if (this.childCare)
        //  this.croppedImage = this.childCare.profileImageName;
        
      //});
      

      //this.childCare = this.authService.getCurrentChildCare();
      //if (this.childCare)
      //  this.croppedImage = this.childCare.profileImageName;
        //this.croppedImage = (this.childCare?.profileImageName) ? this.childCare?.profileImageName : "images/listing/default.jpg";

    });

    
    //this.authService.getChildCares(this.currentUser.id);
    //setTimeout(() => { ($('#myModal') as any).modal('hide'); }, 3000);
    
    
    
    //setTimeout(() => { this.authService.getChildCares(this.currentUser.id); }, 3000);
  }

  fileChangeEvent(event: any): void {
    ($('#myModal') as any).modal('show');
    this.imageChangedEvent = event;
    this.editImage = true;    
  }

  cancelToUpdatePhoto(): void {
    this.authService.getChildCares(this.currentUser.id);
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;    
  }

  private getComponentByType(address: Address, type: string): AddressComponent {
    if (!type)
      return null;

    if (!address || !address.address_components || address.address_components.length == 0)
      return null;

    type = type.toLowerCase();

    for (let comp of address.address_components) {
      if (!comp.types || comp.types.length == 0)
        continue;

      if (comp.types.findIndex(x => x.toLowerCase() == type) > -1)
        return comp;
    }

    return null;
  }

  handleAddressChange(address: Address) {    
      this.childCare.displayFacilityAddress = address.name;
      //https://developers.google.com/maps/documentation/javascript/places-autocomplete
      //based on Google documentation.
      const locality = this.getComponentByType(address, "locality");
      const state = this.getComponentByType(address, "administrative_area_level_1");
      const zip = this.getComponentByType(address, "postal_code");

      this.childCare.displayFacilityCity = locality?.short_name;
      this.childCare.displayFacilityState = state?.short_name;
      this.childCare.displayFacilityZipCode = zip?.short_name;

      this.childCare.locationLat = address.geometry.location.lat().toString();
      this.childCare.locationLon = address.geometry.location.lng().toString();
      this.addMarker(this.childCare);    
  }

  updateLicense(text: string) {
    this.license = text;
  }

  addMarker(childcare: ChildCareProvider) {
    this.markers = [];
    this.markers.push({
      position: {
        lat: +childcare?.locationLat,
        lng: +childcare?.locationLon,
      },
      label: {
        color: 'black',
        text: childcare?.facilityName,
      },
      title: childcare?.facilityName,
      info: childcare,
      fillColor: 'white',
      options: {
        animation: google.maps.Animation.BOUNCE,
      },
    })
  }

  getProviderTypeString(id: number): string {
    switch (id) {
      case 1:
        return "main.providerOption1";
        break;
      case 2:
        return "main.providerOption2";
        break;
      case 3:
        return "main.providerOption3";
        break;
      case 4:
        return "main.providerOption4";
        break;
      case 5:
        return "main.providerOption5";
        break;
    }
  }

  getOpenStatusDescription() {

    if (this.childCare?.openStatusId == 0) return 'Closed';
    else if (this.childCare?.openStatusId == 1) return 'Open';
    else if (this.childCare?.openStatusId == 2) return 'Closed but accepting applications';

  }
  getOpenStatusClass() {

    if (this.childCare?.openStatusId == 0) return 'badge badge-danger';
    else if (this.childCare?.openStatusId == 1) return 'badge badge-success';
    else if (this.childCare?.openStatusId == 2) return 'badge badge-warning';

  }
  getStatus() {
    if(this.childCare.mondayHours == "" &&
       this.childCare.tuesdayHours == "" &&
       this.childCare.wednesdayHours == "" &&
       this.childCare.thursdayHours == "" &&
       this.childCare.fridayHours == "" &&
       this.childCare.saturdayHours == "" &&
       this.childCare.sundayHours == "")
    {
      return false;
    }
    else {
      return true;
    }
  }

  returntoAdmin() {
    localStorage.removeItem('currentProvider');
    localStorage.removeItem('isImpersonation');
    this.authService.setImpersonation(false);
    this.router.navigate(['admin-providers']);
  }

  ngOnDestroy(): void {
    //cleaning
    localStorage.removeItem('currentProvider');
    localStorage.removeItem('isImpersonation');
    localStorage.removeItem('currentUser');
    this.authService.setImpersonation(false);
}

  updateServiceCall(sectionId: number) {
    let userId = this.userService.currentUser === null ? this.currentUser?.id : this.userService.currentUser.id;
    if (!userId)
      userId = this.childCare.userId;

    this.cleanHoursLogic(this.childCare);
    
    this.childCareService.updateChildCare(this.childCare, userId, sectionId).subscribe(result => {
      this.childCare = result;
      //if (!this.isImpersonation && this.currentUser)
      //  this.authService.getChildCares(this.currentUser.id);
      //else {
        this.authService.setCurrentChildCareValue(this.childCare);
        localStorage.setItem("currentProvider", JSON.stringify(this.childCare));
      //};
      if (this.activeLang === "en") {
        this.toastr.success("Changes Saved Successfully");
      }
      else {
        this.toastr.success("Cambios guardados Correctamente");
      }
    });
  }

  cleanHoursLogic(provider: ChildCareProvider) {

    //monday
    if (provider.standarizedHours.mondayStart == '' || provider.standarizedHours.mondayEnd == '') {
      provider.standarizedHours.mondayStart = '';
      provider.standarizedHours.mondayEnd = '';
    }
    //tuesday
    if (provider.standarizedHours.tuesdayStart == '' || provider.standarizedHours.tuesdayEnd == '') {
      provider.standarizedHours.tuesdayStart = '';
      provider.standarizedHours.tuesdayEnd = '';
    }
    //wednesday
    if (provider.standarizedHours.wednesdayStart == '' || provider.standarizedHours.wednesdayEnd == '') {
      provider.standarizedHours.wednesdayStart = '';
      provider.standarizedHours.wednesdayEnd = '';
    }
    //thursday
    if (provider.standarizedHours.thursdayStart == '' || provider.standarizedHours.thursdayEnd == '') {
      provider.standarizedHours.thursdayStart = '';
      provider.standarizedHours.thursdayEnd = '';
    }
    //friday
    if (provider.standarizedHours.fridayStart == '' || provider.standarizedHours.fridayEnd == '') {
      provider.standarizedHours.fridayStart = '';
      provider.standarizedHours.fridayEnd = '';
    }
    //saturday
    if (provider.standarizedHours.saturdayStart == '' || provider.standarizedHours.saturdayEnd == '') {
      provider.standarizedHours.saturdayStart = '';
      provider.standarizedHours.saturdayEnd = '';
    }
    //sunday
    if (provider.standarizedHours.sundayStart == '' || provider.standarizedHours.sundayEnd == '') {
      provider.standarizedHours.sundayStart = '';
      provider.standarizedHours.sundayEnd = '';
    }
  }

  getSetting() {
    // call service
    this.reminderService.getProviderReminderSettings(this.childCare.id).subscribe(res => {

      console.log(res);
      
      //if (res?.isSubscribed) {
      if (res) {


        this.providerReminderSettings = res;

      }
    });
  }

  getPreviousSubscribedInfo() {

    // call service
    this.reminderService.getProviderReminderSettings(this.childCare.id).subscribe(res => {

      console.log(res);

      if (res) {    

        this.providerReminderSettings = res;
        this.providerReminderSettings.isSubscribed = true;// because database result is false
        
      }
    });
  }

  saveReminder () {


    this.reminderService.saveProviderReminderSettings(this.providerReminderSettings, this.childCare.id).subscribe(res => {
      console.log(res);
      if (res.result === "error") {
        this.toastr.error(res.message)
        return;
      }
      if (this.activeLang === "en") {
        this.toastr.success("Changes Saved Successfully");
      }
      else {
        this.toastr.success("Cambios guardados Correctamente");
      }
      //console.log(this.currentUser.id);
      this.authService.getChildCares(this.currentUser.id);
    });
  }

  onSubmit() {

    this.saveReminder();
  }

  unsubscribeReminder() {
    this.reminderService.deleteReminderSettings(this.childCare.id).subscribe(res => {
      if (this.activeLang === "en") {
        this.toastr.success("Unsubscribed from reminders.");
      }
      else {
        this.toastr.success("Se quitaron los recordatorios.");
      }
    });
  }

  toggleOptIn() {
    
    this.providerReminderSettings.isSubscribed = !this.providerReminderSettings.isSubscribed;
    this.providerReminderSettings.frequencyId = 1;

   // this.getPreviousSubscribedInfo();


    if (!this.providerReminderSettings.isSubscribed) {
      //call unsubscribe service
      this.unsubscribeReminder();
      this.authService.getChildCares(this.currentUser.id);
      //this.providerReminderSettings.smsEnabled = false;
      //this.providerReminderSettings.emailEnabled = false;
      //this.smsReminder = false;
      //this.emailReminder = false;
    
      //this.providerReminderSettings.email = '';
      //this.providerReminderSettings.cellPhoneNumber = '';
      //this.providerReminderSettings.frequencyId = 0;
    }

   
  }

  toggleSmsReminder() {
    this.providerReminderSettings.smsEnabled = !this.providerReminderSettings.smsEnabled;
    //this.smsReminder = !this.smsReminder
    //if (this.providerReminderSettings.smsEnabled) {
    //  //this.frecuency = true;
    //}
  }

  toggleEmailReminder() {
    this.providerReminderSettings.emailEnabled = !this.providerReminderSettings.emailEnabled;
    //this.emailReminder = !this.emailReminder;
    //if (this.providerReminderSettings.emailEnabled) {
    // // this.frecuency = true;
    //}
  }

  onOptionsSelected(value) {
    //show option selected
    this.selectedOption = value;
  }

  //MaxCapacity(i: number) {
  //  //if (isNaN(i))
  //  //  return new Array();
  //  if (!isNaN(i))
  //    return  new Array(i);
  //}
  MaxCapacity(i: number) {     
      return new Array(i);
  }

  //opening hours
  enableSeccion() {
    const x = document.getElementById("hours-container");
    if (x.style.display === "none") {
      x.style.display = "block";
    }
    //else {
    //  x.style.display = "none";
    //}

    //read only container
    const y = document.getElementById("readonly-hours-container");
    if (y.style.display === "block") {
      y.style.display = "none";
    }
  }
  //opening hours
  hideSeccion() {
    const x = document.getElementById("hours-container");
    if (x.style.display === "block") {
      x.style.display = "none";
    }
    //else {
    //  x.style.display = "none";
    //}

    //read only container
    const y = document.getElementById("readonly-hours-container");
    if (y.style.display === "none") {
      y.style.display = "block";
    }
  }

  //Display contact info
  
  enableDisplayContactSeccion() {
    const x = document.getElementById("edit-fields");
    if (x.style.display === "none") {
      x.style.display = "block";
    }
    //read only container
    const y = document.getElementById("readonly-display-contact");
    if (y.style.display === "block") {
      y.style.display = "none";
    }
  }
  
  hideDisplayContactSeccion() {
    const x = document.getElementById("edit-fields");
    if (x.style.display === "block") {
      x.style.display = "none";
    }
    //read only container
    const y = document.getElementById("readonly-display-contact");
    if (y.style.display === "none") {
      y.style.display = "block";
    }
  }
  viewHideHours() {
    const x = document.getElementById("hours-container");
    const z = document.getElementById("readonly-hours-container");
    if (x.style.display === "none") {
      x.style.display = "block";
      z.style.display = "none";
    } else {
      x.style.display = "none";
      z.style.display = "block";
    }

    //dinamyc title
    const y = document.getElementById("viewHide");
    if (y.innerHTML === "Edit") {
      y.innerHTML = "Cancel";
    } else {
      y.innerHTML = "Edit";
    }


  }

  viewHideContactInfo() {
    const x = document.getElementById("edit-fields");
    const z = document.getElementById("readonly-display-contact");
    if (x.style.display === "none") {
      x.style.display = "block";
      z.style.display = "none";
    } else {
      x.style.display = "none";
      z.style.display = "block";
    }

    //dinamyc title
    const y = document.getElementById("viewHideContact");
    if (y.innerHTML === "Edit") {
      y.innerHTML = "Cancel";
    } else {
      y.innerHTML = "Edit";
    }


  }

}
