import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/auth/authentication.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})

export class LoginComponent {
  user: string;
  password: string;
  error: string;
  loading = false;

  constructor(
      private authenticationService: AuthenticationService,
      private router: Router,) { }

  signIn(): void {
    if(!this.validEmail()) this.error = "The email is not valid";
    else{
      this.loading = true;
      this.authenticationService.exchangeIdToken(this.user, this.password).subscribe(
        (result) => {
          if (result) {
            if (result.result === "reset")
              this.router.navigate(['reset-password/0']);
            if (result.result === "error") {
               console.log(result.result);
               this.error = result.message;
            }
            if (result.user)
            {
              localStorage.setItem("jwt", result.user.token);
              if(result.user.userType === 2) {
                this.router.navigate(['admin-providers']);
              }
              else {
                this.router.navigate(['profile']);
              }
            }
          }
        },
        error => {
            console.log(error);
            this.error = "Hmm. That didn't work.";
            this.loading = false;
        });
      this.loading = false;
    }
  }
  
  //TODO:  Logic not needed.   Delete in a future release.
  validEmail() {
    return true;
  }

}
