import { StandarizedHours } from "./standarized-hour";
import { AdditionalFilters } from "./additional-filters";

export class ChildCareProvider {
  id: number;

  isLicenseExempt: boolean;

  facilityName: string;
  contactName: string;
  facilityLicenseNumber: string;

  facilityEmail: string;
  facilityAltEmail: string;
  facilityPhoneNumber: string;
  facilityAddress: string;
  facilityCity: string;
  facilityZipCode: string;
  facilityTypeId: number;
  openStatusId: number;// Open/Close
  openStatusIdDescription: string;// Open/Close/close&enrolling
  isDataPublishable: boolean;  
  isThereCapacity: boolean;//openings
  //infantAvailableCapacity: string;
  //toddlerAvailableCapacity: string;
  //preschoolAvailableCapacity: string;
  //schoolAgeAvailableCapacity: string;
  mondayHours: string;
  tuesdayHours: string;
  wednesdayHours: string;
  thursdayHours: string;
  fridayHours: string;
  saturdayHours: string;
  sundayHours: string;

  isSubsidy: boolean;
  isStepUpToQuality: boolean;
  // new added
  isAvailabilityDataAvailable: boolean;
  responseId: string;
  facilityState: string;
  displayFacilityPhoneNumber: string;
  displayFacilityAddress: string;
  displayFacilityCity: string;
  displayFacilityZipCode: string;
  displayFacilityState: string;
  displayWebsite: string;
  displayEmail: string;
  facilityType: string;
  facilityTypeFilterId: number;
  spanish: boolean;
  disability: boolean;
  locationLon: string;
  locationLat: string;
  profileImageName: string;
  isRegistered: boolean;
  applicationPhoneNumber: string
  isApplicationPhoneNumberCellPhone?: boolean;
  isRemoteLearningAvailable: boolean;
  status: number;
  agesFrom: string;
  agesTo: string;
  capacity: number;
  sutqRating: string;
  isInfant: boolean;
  isToddler: boolean;
  isPreschool: boolean;
  isSchoolAge: boolean;
  standarizedHours: StandarizedHours;
  aditionalFilters: AdditionalFilters;
  availabilityLastUpdated: string;
  userId: number;
  
  providerDataSource: number;
  provaiderDatasourceType: string;

  infantCapacity: number;
  toddlerCapacity: number;
  preschoolCapacity: number;
  schoolAgeCapacity: number;
  providerCapacity: number;
  infantAvailability: number;
  toddlerAvailability: number;
  preschoolAvailability: number;
  schoolAgeAvailability: number;
  insertedDHHSDate: string;
  county: string;
  registrationDate: string;
  primaryUser: string;
  primaryUserLastLogin: string;
  additionalUser1: string;
  additionalUser1LastLogin: string;
  additionalUser2: string;
  additionalUser2LastLogin: string;
  headStartCapacity: number;
  isEmptyAllDayHours: boolean;
  
}
