import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { User, AuthenticationService } from '../auth/authentication.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AdminService {
  _baseUrl: string;
  currentUser: User;

  constructor(private http: HttpClient, @Inject('BASE_URL')
  private baseUrl: string,    
    private oAuth: AuthenticationService) {
    this._baseUrl = baseUrl;
    this.oAuth.currentUser.subscribe(res =>
      this.currentUser = res
    );
  }
  testAdmin(id: BigInteger) {
    return this.http.get<any>(`${this._baseUrl}api/admin/admin-test/${id}`);
  }

  //primary-mgmt-info/{providerId}
  getPrimaryMgmtInfo(providerId) {
    return this.http.get<any[]>(`${this._baseUrl}api/admin/user-mgmt/info/${providerId}`);
  }

  //POST: "primary-mgmt-email-check"
  //newPrimaryEmailCheck(newEmail: string): Observable<any> {
  //  const headers = new HttpHeaders({ 'Content-Type': 'application/json' })
  //  // headers.append('Authorization', 'Bearer');
  //  return this.http.post<any>(`${this._baseUrl}api/admin/primary-mgmt-email-check`, JSON.stringify(newEmail), { headers: headers });
  //}


  //user-mgmt/add
  createUser(providerId: number, email: string, updateDisplayEmailAddress: boolean,userType:number): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })

    const newUser = {
      loggedInUserId: this.currentUser.id,
      providerId: providerId,
      emailAddress: email,
      providerUserTypeId: userType,
      UpdateDisplayEmailAddress: updateDisplayEmailAddress
    };

    return this.http.post<any>(`${this._baseUrl}api/admin/user-mgmt/add`, newUser, { headers: headers });
  }

  deleteUser(userId:number,providerId: number, email: string): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })

    const user = {
      loggedInUserId: this.currentUser.id,
      providerId: providerId,
      userId: userId,
      emailAddress: email,
      
    };

    return this.http.post<any>(`${this._baseUrl}api/admin/user-mgmt/delete`, user, { headers: headers });
  }


  editUser(userId: number, providerId: number, originalEmail: string, newEmail: string, updateDisplayEmailAddress: boolean, userType:number): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' })

    //TODO: validate admin userId exist
    const user = {
      loggedInUserId: this.currentUser.id,
      userId: userId,
      providerId: providerId,
      providerUserTypeId: userType, 
      originalEmailAddress: originalEmail,
      NewEmailAddress: newEmail,
      UpdateDisplayEmailAddress: updateDisplayEmailAddress
     // SendResetPasswordEmail: false //TODO, How to get tis value?
    };

    return this.http.post<any>(`${this._baseUrl}api/admin/user-mgmt/edit`, user, { headers: headers });
  }

}
