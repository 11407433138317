import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FilteredRequest, CapacityRequestService } from 'src/app/service/capacity-request.service';
import { ChildCareProvider } from 'src/app/models/child-care-provider';
import { GoogleMap, MapInfoWindow, MapMarker } from '@angular/google-maps';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { GooglePlaceDirective } from 'ngx-google-places-autocomplete';
import { AuthenticationService } from '../../auth/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit, AfterViewInit {

  // Imports for the map component
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap
  @ViewChild(MapInfoWindow, { static: false }) info: MapInfoWindow
  @ViewChild("placesRef") placesRef: GooglePlaceDirective;
  //@ViewChild('name') inputNextInfant; // accessing the reference element

  closedImg = "images/listing/avater-1.jpg";
  openImg = "images/listing/avater-2.jpg";
  openEnrolling = "images/listing/closedEnrolling.jpg";
  notAvailableImg = "images/listing/avater-3.jpg";
  public today: Date = new Date();
  public selectedMileValue = "0";
  public isGeoCall = 0;
  //public isCheckedInfant: boolean = false;
  
  // Map initial attributes
  zoom = 13;

  center = {
    lat: 41.24,
    lng: -96.06,
  }
  //Info Window options
  optionsInfoWin = { minWidth: 350 };
 // optionsInfoWin = { disableAutoPan: true };
  

  // Map initial values
  options: google.maps.MapOptions = {
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: false,
    mapTypeId: 'roadmap',
    maxZoom: 25,
    minZoom: 6,
    disableDefaultUI: true,
    // Removes markers from the map
    styles: [
      {
        featureType: 'poi',
        stylers: [{ visibility: 'off' }]
      },
      {
        featureType: 'road.highway',
        elementType: 'labels',
        stylers: [{ visibility: 'off' }]
      },
    ],

  }
  // Array of markers showed on the map
  markers = []
  eventMarkers = []
  // class that shows the info from the marker on click
  infoContent = new ChildCareProvider();
  // Search options for the auto complete input
  SearchOptions = {
    types: [],
    componentRestrictions: {
      country: 'us'
    }
  };


  // Search, paging and listing variables
  filter: FilteredRequest;
  childCares: ChildCareProvider[];
  previousPage = 0;
  nextPage = 2;
  totalCount: number;
  totalPages: number;
  hasNext: boolean;
  hasPrevious: boolean;
  ProviderTypes = [];
  providerComboText = "main.providerStatus_OpenAccept";
  subcidyComboText = "main.Financial_All";
  stepUpQualityComboText = "main.SUTQ_All";
  zipErrorStatus = false;
  addressErrorStatus = false;
  zipErrorClass = "form-control";
  mapActive = true;
  zip: string;
  scrWidth: number;
  loading = false;
  timeLeft = 45;
  interval;
  haveCenter = false;
  isAgeSearch = false;
  isClosedSUTQ = '';
  isClosedHead = '';
  isClosedFeedback = '';

  constructor(private capacityRequestService: CapacityRequestService, private authService: AuthenticationService, private router: Router) {
    // Persist the map active/inactive selection
    //if (localStorage.getItem("mapActive")) {
    //  this.mapActive = (localStorage.getItem("mapActive") == "true");
    //}
    //this.scrWidth = window.innerWidth;
    //if (this.scrWidth <= 1300) {
    //  this.mapActive = false;
    //}
  }

  tConvert(time: any) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1); // Remove full string match value
      time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

  displayLegend() {
    const icons: Record<string, any> = {
      parking: {
        name: "Licensed Child Care - <br/><div style='margin-left: 18px;'>Availability Reported</div>",//blue
        icon: "images/location-blue.svg",
        style: "",
       // alt: "alt='Licensed Child Care Availability Reported'", 
        title: "title='Map legend blue pin is Licensed Child Care - Availability Reported.'"
      },
      info: {
        name: "Licensed Child Care", //gray
        icon: "images/location-gray.svg",
        style: "",
       // alt: "alt='Licensed Child Care'",
        title: "title='Map legend gray pin is Licensed Child Care.'",
      },
      library: {
        name: "Head Start",
        icon: "images/location-purple.svg",
        style: "",
       // alt: "alt='Head Start'",
        title: "title='Map legend purple pin is Head Start.'"
        // style: "style='margin-left: -5px;'"
      }
      //prek: {
      //  name: "Pre K", //orange
      //  icon: "images/location-orange.svg",
      //  style: ""
      //}
    };
    const legend = document.getElementById("legend") as HTMLElement;

    for (const key in icons) {
      const type = icons[key];
      const name = type.name;
      const icon = type.icon;
      const style = type.style;
      //const alt = type.alt;
      const title = type.title;
      const div = document.createElement("div");
 
      div.innerHTML = '<img ' +title+ style + ' src="' + icon + '">' + name;
      legend.appendChild(div);
    }

     this.map.controls[google.maps.ControlPosition.LEFT_BOTTOM].push(legend);
  }
  ngOnInit(): void {


    //cleaning
    localStorage.removeItem('currentProvider');
    localStorage.removeItem('isImpersonation');
    localStorage.removeItem('currentUser');
    this.authService.setImpersonation(false);


    // Calculate map center with the user's location
    this.haveCenter = true;
    navigator.geolocation.getCurrentPosition(position => {
      this.center = {
        lat: position.coords.latitude,
        lng: position.coords.longitude,
      }
      this.getLocationsWithCenter(this.center);
      this.haveCenter = false;
    })
    // Create the filter to get childcares with default values
    this.filter = new FilteredRequest(1, 12);
    this.filter.openAccept = true;
    this.capacityRequestService.filterRequest(this.filter).subscribe(result => {
      const headers = JSON.parse(result.headers.get("X-Pagination"));
      this.childCares = result.body;
      //console.log(this.tConvert(null));
      //this.childCares = result.body.map(x => this.tConvert(x.standarizedHours.mondayStart));
      //convert to am/pm
      this.childCares.forEach((val, index) => {
        if (this.childCares[index].standarizedHours) {
          this.childCares[index].standarizedHours.mondayStart = val.standarizedHours?.mondayStart ? this.tConvert(val.standarizedHours?.mondayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.mondayEnd = val.standarizedHours?.mondayEnd ? this.tConvert(val.standarizedHours?.mondayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.tuesdayStart = val.standarizedHours?.tuesdayStart ? this.tConvert(val.standarizedHours?.tuesdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.tuesdayEnd = val.standarizedHours?.tuesdayEnd ? this.tConvert(val.standarizedHours?.tuesdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.wednesdayStart = val.standarizedHours?.wednesdayStart ? this.tConvert(val.standarizedHours?.wednesdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.wednesdayEnd = val.standarizedHours?.wednesdayEnd ? this.tConvert(val.standarizedHours?.wednesdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.thursdayStart = val.standarizedHours?.thursdayStart ? this.tConvert(val.standarizedHours?.thursdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.thursdayEnd = val.standarizedHours?.thursdayEnd ? this.tConvert(val.standarizedHours?.thursdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.fridayStart = val.standarizedHours?.fridayStart ? this.tConvert(val.standarizedHours?.fridayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.fridayEnd = val.standarizedHours?.fridayEnd ? this.tConvert(val.standarizedHours?.fridayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.saturdayStart = val.standarizedHours?.saturdayStart ? this.tConvert(val.standarizedHours?.saturdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.saturdayEnd = val.standarizedHours?.saturdayEnd ? this.tConvert(val.standarizedHours?.saturdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.sundayStart = val.standarizedHours?.sundayStart ? this.tConvert(val.standarizedHours?.sundayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.sundayEnd = val.standarizedHours?.sundayEnd ? this.tConvert(val.standarizedHours?.sundayEnd.slice(0, 5)) : 'Closed';

          if (this.childCares[index].standarizedHours.mondayStart === 'Closed' || this.childCares[index].standarizedHours.mondayEnd === 'Closed')
            this.childCares[index].standarizedHours.mIsCLosed = true;
          if (this.childCares[index].standarizedHours.tuesdayStart === 'Closed' || this.childCares[index].standarizedHours.tuesdayEnd === 'Closed')
            this.childCares[index].standarizedHours.tIsCLosed = true;
          if (this.childCares[index].standarizedHours.wednesdayStart === 'Closed' || this.childCares[index].standarizedHours.wednesdayEnd === 'Closed')
            this.childCares[index].standarizedHours.wIsCLosed = true;
          if (this.childCares[index].standarizedHours.thursdayStart === 'Closed' || this.childCares[index].standarizedHours.thursdayEnd === 'Closed')
            this.childCares[index].standarizedHours.thIsCLosed = true;
          if (this.childCares[index].standarizedHours.fridayStart === 'Closed' || this.childCares[index].standarizedHours.fridayEnd === 'Closed')
            this.childCares[index].standarizedHours.fIsCLosed = true;
          if (this.childCares[index].standarizedHours.saturdayStart === 'Closed' || this.childCares[index].standarizedHours.saturdayEnd === 'Closed')
            this.childCares[index].standarizedHours.sIsCLosed = true;
          if (this.childCares[index].standarizedHours.sundayStart === 'Closed' || this.childCares[index].standarizedHours.sundayEnd === 'Closed')
            this.childCares[index].standarizedHours.suIsCLosed = true;

          //flag to manage hours is empty
          this.checkingClosedHours(this.childCares[index]);
        }
      }
      );

      //console.log(this.childCares);
      this.totalCount = headers.TotalCount;
      this.totalPages = headers.TotalPages;
      this.hasNext = headers.HasNext;
      this.hasPrevious = headers.HasPrevious;
    });
  }

  ngAfterViewInit() {
    this.getLocationsWithCenter(this.center);
    this.haveCenter = false;
    this.isClosedSUTQ = sessionStorage.getItem('closed-sutq');
    this.isClosedHead = sessionStorage.getItem('closed-head');
    this.isClosedFeedback = sessionStorage.getItem('closed-feedback');
    this.displayLegend();   
  }

  saveChanges() {
    this.loading = true;
    this.calculatePage(1);
    //if typed address and no selected distance then set 5 miles as default
    if (this.filter.searchLat !== null && this.filter.searchLng !== null && !this.filter.distance) {
      this.filter.distance = '5';
      this.selectedMileValue = '5';
    }

    if (this.isGeoCall === 1 && !this.filter.distance) {
      setTimeout(() => {  }, 3000);
      this.filter.searchLat = parseFloat(localStorage.getItem("lat"));
      this.filter.searchLng = parseFloat(localStorage.getItem("lng"));
      this.filter.distance = '5';
      this.selectedMileValue = '5';


      //setTimeout(function () {

      //  this.filter.searchLat = parseFloat(localStorage.getItem("lat"));
      //  this.filter.searchLng = parseFloat(localStorage.getItem("lng"));
      //  this.filter.distance = '5';
      //  this.selectedMileValue = '5';
        
        
      //}, 3000);

    }


    this.capacityRequestService.filterRequest(this.filter).subscribe(result => {
      const headers = JSON.parse(result.headers.get("X-Pagination"));
      this.childCares = result.body;
      //collapsed advance search container after request completed.
      ($(".collapse") as any).collapse('hide');
      //convert to am/pm
      this.childCares.forEach((val, index) => {
        if (this.childCares[index].standarizedHours) {
          this.childCares[index].standarizedHours.mondayStart = val.standarizedHours?.mondayStart ? this.tConvert(val.standarizedHours?.mondayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.mondayEnd = val.standarizedHours?.mondayEnd ? this.tConvert(val.standarizedHours?.mondayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.tuesdayStart = val.standarizedHours?.tuesdayStart ? this.tConvert(val.standarizedHours?.tuesdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.tuesdayEnd = val.standarizedHours?.tuesdayEnd ? this.tConvert(val.standarizedHours?.tuesdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.wednesdayStart = val.standarizedHours?.wednesdayStart ? this.tConvert(val.standarizedHours?.wednesdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.wednesdayEnd = val.standarizedHours?.wednesdayEnd ? this.tConvert(val.standarizedHours?.wednesdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.thursdayStart = val.standarizedHours?.thursdayStart ? this.tConvert(val.standarizedHours?.thursdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.thursdayEnd = val.standarizedHours?.thursdayEnd ? this.tConvert(val.standarizedHours?.thursdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.fridayStart = val.standarizedHours?.fridayStart ? this.tConvert(val.standarizedHours?.fridayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.fridayEnd = val.standarizedHours?.fridayEnd ? this.tConvert(val.standarizedHours?.fridayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.saturdayStart = val.standarizedHours?.saturdayStart ? this.tConvert(val.standarizedHours?.saturdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.saturdayEnd = val.standarizedHours?.saturdayEnd ? this.tConvert(val.standarizedHours?.saturdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.sundayStart = val.standarizedHours?.sundayStart ? this.tConvert(val.standarizedHours?.sundayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.sundayEnd = val.standarizedHours?.sundayEnd ? this.tConvert(val.standarizedHours?.sundayEnd.slice(0, 5)) : 'Closed';

          if (this.childCares[index].standarizedHours.mondayStart === 'Closed' || this.childCares[index].standarizedHours.mondayEnd === 'Closed')
            this.childCares[index].standarizedHours.mIsCLosed = true;
          if (this.childCares[index].standarizedHours.tuesdayStart === 'Closed' || this.childCares[index].standarizedHours.tuesdayEnd === 'Closed')
            this.childCares[index].standarizedHours.tIsCLosed = true;
          if (this.childCares[index].standarizedHours.wednesdayStart === 'Closed' || this.childCares[index].standarizedHours.wednesdayEnd === 'Closed')
            this.childCares[index].standarizedHours.wIsCLosed = true;
          if (this.childCares[index].standarizedHours.thursdayStart === 'Closed' || this.childCares[index].standarizedHours.thursdayEnd === 'Closed')
            this.childCares[index].standarizedHours.thIsCLosed = true;
          if (this.childCares[index].standarizedHours.fridayStart === 'Closed' || this.childCares[index].standarizedHours.fridayEnd === 'Closed')
            this.childCares[index].standarizedHours.fIsCLosed = true;
          if (this.childCares[index].standarizedHours.saturdayStart === 'Closed' || this.childCares[index].standarizedHours.saturdayEnd === 'Closed')
            this.childCares[index].standarizedHours.sIsCLosed = true;
          if (this.childCares[index].standarizedHours.sundayStart === 'Closed' || this.childCares[index].standarizedHours.sundayEnd === 'Closed')
            this.childCares[index].standarizedHours.suIsCLosed = true;
          //flag to manage hours is empty
          this.checkingClosedHours(this.childCares[index]);

        }

      });

      // console.log(this.childCares);      
      this.totalCount = headers.TotalCount;
      this.totalPages = headers.TotalPages;
      this.hasNext = headers.HasNext;
      this.hasPrevious = headers.HasPrevious;
      this.showLoading();
      (this.filter.IsRemoteLearningAvailable) ? this.getCustomLocations() : this.getLocations();

    });
  }

  showLoading() {
    this.interval = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        this.timeLeft = 45;
      }
    }, 1000);
    clearInterval(this.interval);
    this.loading = false;
  }

  getLocations(): void {
    let latlng = { lat: null, lng: null }
    if (this.haveCenter) latlng = this.center;
    this.capacityRequestService.getLocations(this.filter, latlng).subscribe(res => {
      this.markers = [];
      let result = res.body;
      result.forEach(element => {
        this.addMarker(element);
      });
      this.rePositionCenter(this.markers);
    });
  }

  getCustomLocations(): void {
    this.markers = [];
    let result = this.childCares;
    result.forEach(element => {
      this.addMarker(element);
    });
    this.rePositionCenter(this.markers);
  }

  getLocationsWithCenter(center: any): void {
    this.capacityRequestService.getLocations(this.filter, center).subscribe(res => {
      this.markers = [];
      let result = res.body;
      result.forEach(element => {
        this.addMarker(element);
      });
    });
  }

  changePageSize(size: string) {
    switch (size) {
      case "12":
        this.filter.pageSize = 12;
        break;
      case "15":
        this.filter.pageSize = 15;
        break;
      case "50":
        this.filter.pageSize = 50;
        break;
      case "100":
        this.filter.pageSize = 100;
        break;
      case "0":
        this.filter.pageSize = 0;
        break;
    }
    this.calculatePage(1);
    this.capacityRequestService.filterRequest(this.filter).subscribe(result => {
      const headers = JSON.parse(result.headers.get("X-Pagination"));
      this.childCares = result.body;
      //convert to am/pm
      this.childCares.forEach((val, index) => {
        if (this.childCares[index].standarizedHours) {
          this.childCares[index].standarizedHours.mondayStart = val.standarizedHours?.mondayStart ? this.tConvert(val.standarizedHours?.mondayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.mondayEnd = val.standarizedHours?.mondayEnd ? this.tConvert(val.standarizedHours?.mondayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.tuesdayStart = val.standarizedHours?.tuesdayStart ? this.tConvert(val.standarizedHours?.tuesdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.tuesdayEnd = val.standarizedHours?.tuesdayEnd ? this.tConvert(val.standarizedHours?.tuesdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.wednesdayStart = val.standarizedHours?.wednesdayStart ? this.tConvert(val.standarizedHours?.wednesdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.wednesdayEnd = val.standarizedHours?.wednesdayEnd ? this.tConvert(val.standarizedHours?.wednesdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.thursdayStart = val.standarizedHours?.thursdayStart ? this.tConvert(val.standarizedHours?.thursdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.thursdayEnd = val.standarizedHours?.thursdayEnd ? this.tConvert(val.standarizedHours?.thursdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.fridayStart = val.standarizedHours?.fridayStart ? this.tConvert(val.standarizedHours?.fridayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.fridayEnd = val.standarizedHours?.fridayEnd ? this.tConvert(val.standarizedHours?.fridayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.saturdayStart = val.standarizedHours?.saturdayStart ? this.tConvert(val.standarizedHours?.saturdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.saturdayEnd = val.standarizedHours?.saturdayEnd ? this.tConvert(val.standarizedHours?.saturdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.sundayStart = val.standarizedHours?.sundayStart ? this.tConvert(val.standarizedHours?.sundayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.sundayEnd = val.standarizedHours?.sundayEnd ? this.tConvert(val.standarizedHours?.sundayEnd.slice(0, 5)) : 'Closed';

          if (this.childCares[index].standarizedHours.mondayStart === 'Closed' || this.childCares[index].standarizedHours.mondayEnd === 'Closed')
            this.childCares[index].standarizedHours.mIsCLosed = true;
          if (this.childCares[index].standarizedHours.tuesdayStart === 'Closed' || this.childCares[index].standarizedHours.tuesdayEnd === 'Closed')
            this.childCares[index].standarizedHours.tIsCLosed = true;
          if (this.childCares[index].standarizedHours.wednesdayStart === 'Closed' || this.childCares[index].standarizedHours.wednesdayEnd === 'Closed')
            this.childCares[index].standarizedHours.wIsCLosed = true;
          if (this.childCares[index].standarizedHours.thursdayStart === 'Closed' || this.childCares[index].standarizedHours.thursdayEnd === 'Closed')
            this.childCares[index].standarizedHours.thIsCLosed = true;
          if (this.childCares[index].standarizedHours.fridayStart === 'Closed' || this.childCares[index].standarizedHours.fridayEnd === 'Closed')
            this.childCares[index].standarizedHours.fIsCLosed = true;
          if (this.childCares[index].standarizedHours.saturdayStart === 'Closed' || this.childCares[index].standarizedHours.saturdayEnd === 'Closed')
            this.childCares[index].standarizedHours.sIsCLosed = true;
          if (this.childCares[index].standarizedHours.sundayStart === 'Closed' || this.childCares[index].standarizedHours.sundayEnd === 'Closed')
            this.childCares[index].standarizedHours.suIsCLosed = true;

          //flag to manage hours is empty
          this.checkingClosedHours(this.childCares[index]);
        }
      }
      );
      this.totalCount = headers.TotalCount;
      this.totalPages = headers.TotalPages;
      this.hasNext = headers.HasNext;
      this.hasPrevious = headers.HasPrevious;
      //this.getLocations();

    });

  }

  searchChildCares(term: string): void {
    if (term !== "") this.filter.keyWord = term;
    else this.filter.keyWord = null;
  }

  //autoCompleteLogic() {
  //  // search input
  //  const searchInput = document.getElementById('gmapsinput');

  //  // Google Maps autocomplete
  //  const autocomplete = new google.maps.places.Autocomplete(searchInput);

  //  // Has user pressed the down key to navigate autocomplete options?
  //  let hasDownBeenPressed = false;

  //  // Listener outside to stop nested loop returning odd results
  //  searchInput.addEventListener('keydown', (e) => {
  //    if (e.keyCode === 40) {
  //      hasDownBeenPressed = true;
  //    }
  //  });

  //  // GoogleMaps API custom eventlistener method
  //  google.maps.event.addDomListener(searchInput, 'keydown', (e) => {

  //    // Maps API
  //    //e.stopPropagation();
  //    e.cancelBubble = true;

  //    // If enter key, or tab key
  //    if (e.keyCode === 13 || e.keyCode === 9) {
  //      // If user isn't navigating using arrows and this hasn't ran yet
  //      if (!hasDownBeenPressed && !e.hasRanOnce) {
  //        google.maps.event.trigger(e.target, 'keydown', {
  //          keyCode: 40,
  //          hasRanOnce: true,
  //        });
  //      }
  //    }
  //  });

  //  // Clear the input on focus, reset hasDownBeenPressed
  //  searchInput.addEventListener('focus', () => {
  //    hasDownBeenPressed = false;
  //    searchInput.value = '';
  //  });

  //  // place_changed GoogleMaps listener when we do submit
  //  google.maps.event.addListener(autocomplete, 'place_changed', function () {

  //    // Get the place info from the autocomplete Api
  //    const place = autocomplete.getPlace();

  //    //If we can find the place lets go to it
  //    if (typeof place.address_components !== 'undefined') {
  //      // reset hasDownBeenPressed in case they don't unfocus
  //      hasDownBeenPressed = false;
  //    }

  //  });
  //}

  codeLatLng(callback,firstValue)  {
    const geocoder = new google.maps.Geocoder();
    geocoder.geocode({ "address": firstValue }, function (results, status) {
      if (status == google.maps.GeocoderStatus.OK) {
        const lat = results[0].geometry.location.lat(),
          lng = results[0].geometry.location.lng(),
          // placeName = results[0].address_components[0].long_name,
          latlng = new google.maps.LatLng(lat, lng);
        
        callback(latlng);
        //return latlng;
        localStorage.setItem("lat", lat.toString());
        localStorage.setItem("lng", lng.toString());

        //$(".pac-container .pac-item:first").addClass("pac-selected");
        //$(".pac-container").css("display", "none");
        //$(".pac-container").css("visibility", "hidden");

        //return latlng;
        //this.latGEO = lat;
        //this.lngGEO = lng;

      }
    });
  }

  focusLogic() {
    console.log("lost focus");
    const firstValue = $(".pac-container .pac-item:first").text();    
    
    if (firstValue) {
      $('#gmapsinput').val(firstValue);
      this.isGeoCall = 1;
      // this.codeLatLng(function (addr) {
      //  return addr; 
      //}, firstValue);

      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ "address": firstValue }, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
          const lat = results[0].geometry.location.lat(),
            lng = results[0].geometry.location.lng();    
          
          localStorage.setItem("lat", lat.toString());
          localStorage.setItem("lng", lng.toString());

    

        }
      });

    }   
    
    console.log(firstValue);   

  }

  handleAddressChange(address: Address) {
    if (!address?.geometry)
      this.focusLogic();
    // Save lat and lng of the address selected
    this.filter.searchLat = address.geometry?.location?.lat();
    this.filter.searchLng = address.geometry?.location?.lng();
    this.center.lat = address.geometry?.location?.lat();
    this.center.lng = address.geometry?.location?.lng();
    this.haveCenter = true;
  }

  refreshZip(text: string) {
    this.focusLogic();
    //Refresh zipcode filter with zipcode input
    if (text == "") {
      this.filter.searchLat = null;
      this.filter.searchLng = null;
      this.haveCenter = false;
      this.center = {
        lat: 41.24,
        lng: -96.06,
      }
    }
  }
  //logic() {
  //  const searchInput = document.getElementById('gmapsinput');
  //  // Google Maps autocomplete
  //  const autocomplete = new google.maps.places.Autocomplete(searchInput);
  //  autocomplete.addListener('place_changed', function () {
  //    if (event.keyCode === 13 || event.keyCode === 9) { // detect the enter key
  //      var firstValue = $(".pac-container .pac-item:first").text(); // assign to this variable the first string from the autocomplete dropdown
  //    }
  //    $('#gmapsinput').val(firstValue); // add this string to input
  //    console.log(firstValue); // display the string on your browser console to check what it is
  //    //(...) add the rest of your code here
  //  });

  //}

  searchOpenFacilities(event): void {
    // Check the value on the facilities checkbox to refresh the text and filter
    if (event === "openAc") {
      this.providerComboText = "main.providerStatus_OpenAccept";
      this.filter.openAccept = true;
      this.filter.openNotAccept = false;
      this.filter.closed = false;
    };
    if (event === "openNa") {
      this.providerComboText = "main.providerStatus_OpenNoAccept";
      this.filter.openAccept = false;
      this.filter.openNotAccept = true;
      this.filter.closed = false;
    };
    if (event === "closed") {
      this.providerComboText = "Closed";
      this.filter.openAccept = false;
      this.filter.openNotAccept = false;
      this.filter.closed = true;
    };
    if (event === "default") {
      this.providerComboText = "All";
      this.filter.openAccept = false;
      this.filter.openNotAccept = false;
      this.filter.closed = false;
    }
  }

  searchByCapacity(event): void {  
    //debugger;
    // Check capacity checkbox to  refresh the filter value
    if (event.target.value === "1") { this.filter.infatnt = event.target.checked;   }
    if (event.target.value === "2") { this.filter.toddler = event.target.checked;   }
    if (event.target.value === "3") { this.filter.preschool = event.target.checked; }
    if (event.target.value === "4") { this.filter.schoolAge = event.target.checked; }

    if(this.filter.infatnt || this.filter.toddler || this.filter.preschool || this.filter.schoolAge) this.isAgeSearch = true;
    else this.isAgeSearch = false;

    //if (this.filter.infatnt) {
    //  this.isCheckedInfant = true;
    //}
    //else {
    //  this.isCheckedInfant = false;
    //  this.inputNextInfant.nativeElement.value = 0;
    //}

  }

  searchZip(): void {
    // Search by zip code radius
    this.zipErrorStatus = false;
    this.addressErrorStatus = false;
    if (this.filter.searchLat != null && this.filter.searchLng != null && this.filter.distance != "0" && this.filter.distance != null) {
      this.zipErrorClass = 'form-control'
    }
    else {
      if (this.filter.distance == "0" || this.filter.distance == null) {
        this.zipErrorStatus = true;
        this.zipErrorClass = 'form-control is-invalid'
      }
      if (this.filter.searchLat == null || this.filter.searchLng == null) {
        this.addressErrorStatus = true;
        this.zipErrorClass = 'form-control is-invalid'
      }
    }
  }



  updateMiles(text: string) {
    //refresh distance filter with radius combo
    if (text !== "0")
      this.filter.distance = text;
    else {
      this.filter.distance = null;
      this.haveCenter = false;
    }
  }
  SelectInfantSlot(text: string) {
    
    if (text !== "0")
      this.filter.NextInfantSlotAvailable = text;
    else {
      this.filter.NextInfantSlotAvailable = null;    
    }
  }
  SelectTimeOpenClose(text: string) {

    if (text !== "0")
      this.filter.TimeOpenClosed = text;
    else {
      this.filter.TimeOpenClosed = null;
    }
  }

  calculatePage(currentPage: number): void {
    // handre paging
    this.filter.pageNumber = currentPage;
    this.previousPage = currentPage - 1;
    this.nextPage = currentPage + 1;
  }

  pageRequest(page: number) {
    // Request to get the current page child cares
    this.calculatePage(page);
    this.capacityRequestService.filterRequest(this.filter).subscribe(result => {
      const headers = JSON.parse(result.headers.get("X-Pagination"));
      this.childCares = result.body;
      //convert to am/pm
      this.childCares.forEach((val, index) => {
        if (this.childCares[index].standarizedHours) {
          this.childCares[index].standarizedHours.mondayStart = val.standarizedHours?.mondayStart ? this.tConvert(val.standarizedHours?.mondayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.mondayEnd = val.standarizedHours?.mondayEnd ? this.tConvert(val.standarizedHours?.mondayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.tuesdayStart = val.standarizedHours?.tuesdayStart ? this.tConvert(val.standarizedHours?.tuesdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.tuesdayEnd = val.standarizedHours?.tuesdayEnd ? this.tConvert(val.standarizedHours?.tuesdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.wednesdayStart = val.standarizedHours?.wednesdayStart ? this.tConvert(val.standarizedHours?.wednesdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.wednesdayEnd = val.standarizedHours?.wednesdayEnd ? this.tConvert(val.standarizedHours?.wednesdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.thursdayStart = val.standarizedHours?.thursdayStart ? this.tConvert(val.standarizedHours?.thursdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.thursdayEnd = val.standarizedHours?.thursdayEnd ? this.tConvert(val.standarizedHours?.thursdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.fridayStart = val.standarizedHours?.fridayStart ? this.tConvert(val.standarizedHours?.fridayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.fridayEnd = val.standarizedHours?.fridayEnd ? this.tConvert(val.standarizedHours?.fridayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.saturdayStart = val.standarizedHours?.saturdayStart ? this.tConvert(val.standarizedHours?.saturdayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.saturdayEnd = val.standarizedHours?.saturdayEnd ? this.tConvert(val.standarizedHours?.saturdayEnd.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.sundayStart = val.standarizedHours?.sundayStart ? this.tConvert(val.standarizedHours?.sundayStart.slice(0, 5)) : 'Closed';
          this.childCares[index].standarizedHours.sundayEnd = val.standarizedHours?.sundayEnd ? this.tConvert(val.standarizedHours?.sundayEnd.slice(0, 5)) : 'Closed';

          if (this.childCares[index].standarizedHours.mondayStart === 'Closed' || this.childCares[index].standarizedHours.mondayEnd === 'Closed')
            this.childCares[index].standarizedHours.mIsCLosed = true;
          if (this.childCares[index].standarizedHours.tuesdayStart === 'Closed' || this.childCares[index].standarizedHours.tuesdayEnd === 'Closed')
            this.childCares[index].standarizedHours.tIsCLosed = true;
          if (this.childCares[index].standarizedHours.wednesdayStart === 'Closed' || this.childCares[index].standarizedHours.wednesdayEnd === 'Closed')
            this.childCares[index].standarizedHours.wIsCLosed = true;
          if (this.childCares[index].standarizedHours.thursdayStart === 'Closed' || this.childCares[index].standarizedHours.thursdayEnd === 'Closed')
            this.childCares[index].standarizedHours.thIsCLosed = true;
          if (this.childCares[index].standarizedHours.fridayStart === 'Closed' || this.childCares[index].standarizedHours.fridayEnd === 'Closed')
            this.childCares[index].standarizedHours.fIsCLosed = true;
          if (this.childCares[index].standarizedHours.saturdayStart === 'Closed' || this.childCares[index].standarizedHours.saturdayEnd === 'Closed')
            this.childCares[index].standarizedHours.sIsCLosed = true;
          if (this.childCares[index].standarizedHours.sundayStart === 'Closed' || this.childCares[index].standarizedHours.sundayEnd === 'Closed')
            this.childCares[index].standarizedHours.suIsCLosed = true;

          //flag to manage hours is empty
          this.checkingClosedHours(this.childCares[index]);

        }
      }
      );
      this.totalCount = headers.TotalCount;
      this.totalPages = headers.TotalPages;
      this.hasNext = headers.HasNext;
      this.hasPrevious = headers.HasPrevious;
      //this.getLocations();
      
    });
  }

  getSubsidyRequest(event: any) {
    // Handle subsidy filters
    if (event === "1") {
      this.filter.AcceptsSubsidy = true;
      this.subcidyComboText = "main.financialAssistance_Accept";
    }
    if (event === "2") {
      this.filter.AcceptsSubsidy = false;
      this.subcidyComboText = "main.financialAssistanceNoAccept";
    }
    if (event === "default") {
      this.filter.AcceptsSubsidy = null;
      this.subcidyComboText = "main.Financial_All";
    }
  }

  getProviderByTypeRequest(event: any) {
    // Handle provider type filter
    if (event.target.value === "1") {
      if (event.target.checked) {
        this.ProviderTypes.push("1");
      }
      else {
        this.filter.infatnt = event.target.checked;
        const index = this.ProviderTypes.indexOf("1");
        if (index > -1) {
          this.ProviderTypes.splice(index, 1);
        }
      }
    }
    if (event.target.value === "2") {
      if (event.target.checked) {
        this.ProviderTypes.push("2");
      }
      else {
        this.filter.infatnt = event.target.checked;
        const index = this.ProviderTypes.indexOf("2");
        if (index > -1) {
          this.ProviderTypes.splice(index, 1);
        }
      }
    }
    if (event.target.value === "3") {
      if (event.target.checked) {
        this.ProviderTypes.push("3");
      }
      else {
        this.filter.infatnt = event.target.checked;
        const index = this.ProviderTypes.indexOf("3");
        if (index > -1) {
          this.ProviderTypes.splice(index, 1);
        }
      }
    }
    if (event.target.value === "4") {
      if (event.target.checked) {
        this.ProviderTypes.push("4");
      }
      else {
        this.filter.infatnt = event.target.checked;
        const index = this.ProviderTypes.indexOf("4");
        if (index > -1) {
          this.ProviderTypes.splice(index, 1);
        }
      }
    }
    if (event.target.value === "5") {
      if (event.target.checked) {
        this.ProviderTypes.push("5");
      }
      else {
        this.filter.infatnt = event.target.checked;
        const index = this.ProviderTypes.indexOf("5");
        if (index > -1) {
          this.ProviderTypes.splice(index, 1);
        }
      }
    }
    //Head start Type
    if (event.target.value === "6") {
      if (event.target.checked) {
        this.ProviderTypes.push("6");
      }
      else {
        this.filter.infatnt = event.target.checked;
        const index = this.ProviderTypes.indexOf("6");
        if (index > -1) {
          this.ProviderTypes.splice(index, 1);
        }
      }
    }
    if (event.target.value === "7") {
      if (event.target.checked) {
        this.ProviderTypes.push("7");
      }
      else {
        this.filter.infatnt = event.target.checked;
        const index = this.ProviderTypes.indexOf("7");
        if (index > -1) {
          this.ProviderTypes.splice(index, 1);
        }
      }
    }
    if (event.target.value === "8") {
      if (event.target.checked) {
        this.ProviderTypes.push("8");
      }
      else {
        this.filter.infatnt = event.target.checked;
        const index = this.ProviderTypes.indexOf("8");
        if (index > -1) {
          this.ProviderTypes.splice(index, 1);
        }
      }
    }
    if (event.target.value === "9") {
      if (event.target.checked) {
        this.ProviderTypes.push("9");
      }
      else {
        this.filter.infatnt = event.target.checked;
        const index = this.ProviderTypes.indexOf("9");
        if (index > -1) {
          this.ProviderTypes.splice(index, 1);
        }
      }
    }
    if (event.target.value === "10") {
      if (event.target.checked) {
        this.ProviderTypes.push("10");
      }
      else {
        this.filter.infatnt = event.target.checked;
        const index = this.ProviderTypes.indexOf("10");
        if (index > -1) {
          this.ProviderTypes.splice(index, 1);
        }
      }
    }
    this.filter.ProviderType = this.ProviderTypes.join();
  }

  getStepUpToQualityRequest(event: any) {
    // handle SUTQ filter
    if (event === "1") {
      this.filter.StepUptoQuality = true;
      this.stepUpQualityComboText = "main.SUTQ_Yes";
    }
    if (event === "2") {
      this.filter.StepUptoQuality = false;
      this.stepUpQualityComboText = "main.SUTQ_No";
    }
    if (event === "default") {
      this.filter.StepUptoQuality = null;
      this.stepUpQualityComboText = "main.SUTQ_All";
    }
  }

  getSpecialNeedsRequest(event: any) {
    // Handle special needs filter
    if (event.target.checked) {
      this.filter.disability = true;
    }
    else {
      this.filter.disability = null;
    }
  }

  getSubsidyCheckBoxRequest(event: any) {
    //  subsidy checkbox
    if (event.target.checked) {
      this.filter.AcceptsSubsidy = true;
    }
    else {
      this.filter.AcceptsSubsidy = null;
    }
  }
  getSutqCheckBoxRequest(event: any) {
    //  subsidy checkbox
    if (event.target.checked) {
      this.filter.StepUptoQuality = true;
    }
    else {
      this.filter.StepUptoQuality = null;
    }
  }
  getRemoteLearningRequest(event: any) {
    // Handle special needs filter
    if (event.target.checked) {
      this.filter.IsRemoteLearningAvailable= true;
    }
    else {
      this.filter.IsRemoteLearningAvailable = null;
    }
  }

  getFoodAllergies(event: any) {
    if (event.target.checked) {
      this.filter.foodAllergiesNeeds = true;
    } else {
      this.filter.foodAllergiesNeeds = null;
    }
  }

  getSpanishStaffRequest(event: any) {
    // Handle spanish staff filter
    if (event.target.checked) {
      this.filter.spanish = true;
    }
    else {
      this.filter.spanish = null;
    }
  }

  getIsTransportationRequest(event: any) {
    // Handle isTransportationProvided filter
    if (event.target.checked) {
      this.filter.isTransportationProvided = true;
    }
    else {
      this.filter.isTransportationProvided = null;
    }
  }

  getIsWheelchairAccessiblenRequest(event: any) {
    
    if (event.target.checked) {
      this.filter.isWheelchairAccessible = true;
    }
    else {
      this.filter.isWheelchairAccessible = null;
    }
  }

  getIsparentingClasses(event: any) {
    if (event.target.checked) {
      this.filter.isParentingClasses = true;
    } else {
      this.filter.isParentingClasses = null;
    }
  }
  getIsEngagementOpportunities(event: any) {
    if (event.target.checked) {
      this.filter.isEngagementOpportunities = true;
    } else {
      this.filter.isEngagementOpportunities = null;
    }
  }
  getIsConnectionToServices(event: any) {
    if (event.target.checked) {
      this.filter.isConnectionToServices = true;
    } else {
      this.filter.isConnectionToServices = null;
    }
  }
  getIsProvideRespiteCare(event: any) {
    if (event.target.checked) {
      this.filter.isProvideRespiteCare = true;
    } else {
      this.filter.isProvideRespiteCare = null;
    }
  }
  getIsDropInCare(event: any) {
    if (event.target.checked) {
      this.filter.isDropInCare = true;
    } else {
      this.filter.isDropInCare = null;
    }
  }

  addMarker(childcare: ChildCareProvider) {
    //Add marker to the markers array to show on the map
    if (childcare.locationLat && childcare.locationLon) {
      this.markers.push({
        position: {
          lat: +childcare.locationLat,
          lng: +childcare.locationLon,
        },
        options: {
          icon: this.getMarkerIcon(this.isAgeSearch, childcare.isRegistered, childcare.providerDataSource)
        },
        info: childcare,      
      })
    }
  }

  getMarkerIcon(isSearchActivated: boolean, isRegistered: boolean, providerType: number)
  {
    if (isRegistered && providerType === 1) {
      return 'images/location-blue.svg';
    }
    else if (!isRegistered && providerType === 1) {
      return 'images/location-gray.svg';
    }
    else if (providerType === 2) {
      return "images/location-purple.svg"; //head start
    }
    //else if (providerType === 3) {
    //  return "images/location-orange.svg"; //head start
    //}  
  }

  openInfo(marker: MapMarker, content: ChildCareProvider) {
    // Set the selected child care marker into the infoContent directive to show the card on the marker position
   // this.infoContent = content
    //ondemand request

    this.capacityRequestService.getProviderMarker(content.id).subscribe(res => {
      
      this.infoContent = res;
      //convert to am/pm
      if (this.infoContent.standarizedHours) {
        this.infoContent.standarizedHours.mondayStart = this.infoContent.standarizedHours.mondayStart ? this.tConvert(this.infoContent.standarizedHours.mondayStart.slice(0, 5)) : 'Closed';
        this.infoContent.standarizedHours.mondayEnd = this.infoContent.standarizedHours.mondayEnd ? this.tConvert(this.infoContent.standarizedHours.mondayEnd.slice(0, 5)) : 'Closed';
        this.infoContent.standarizedHours.tuesdayStart = this.infoContent.standarizedHours.tuesdayStart ? this.tConvert(this.infoContent.standarizedHours.tuesdayStart.slice(0, 5)) : 'Closed';
        this.infoContent.standarizedHours.tuesdayEnd = this.infoContent.standarizedHours.tuesdayEnd ? this.tConvert(this.infoContent.standarizedHours.tuesdayEnd.slice(0, 5)) : 'Closed';
        this.infoContent.standarizedHours.wednesdayStart = this.infoContent.standarizedHours.wednesdayStart ? this.tConvert(this.infoContent.standarizedHours.wednesdayStart.slice(0, 5)) : 'Closed';
        this.infoContent.standarizedHours.wednesdayEnd = this.infoContent.standarizedHours.wednesdayEnd ? this.tConvert(this.infoContent.standarizedHours.wednesdayEnd.slice(0, 5)) : 'Closed';
        this.infoContent.standarizedHours.thursdayStart = this.infoContent.standarizedHours.thursdayStart ? this.tConvert(this.infoContent.standarizedHours.thursdayStart.slice(0, 5)) : 'Closed';
        this.infoContent.standarizedHours.thursdayEnd = this.infoContent.standarizedHours.thursdayEnd ? this.tConvert(this.infoContent.standarizedHours.thursdayEnd.slice(0, 5)) : 'Closed';
        this.infoContent.standarizedHours.fridayStart = this.infoContent.standarizedHours.fridayStart ? this.tConvert(this.infoContent.standarizedHours.fridayStart.slice(0, 5)) : 'Closed';
        this.infoContent.standarizedHours.fridayEnd = this.infoContent.standarizedHours.fridayEnd ? this.tConvert(this.infoContent.standarizedHours.fridayEnd.slice(0, 5)) : 'Closed';
        this.infoContent.standarizedHours.saturdayStart = this.infoContent.standarizedHours.saturdayStart ? this.tConvert(this.infoContent.standarizedHours.saturdayStart.slice(0, 5)) : 'Closed';
        this.infoContent.standarizedHours.saturdayEnd = this.infoContent.standarizedHours.saturdayEnd ? this.tConvert(this.infoContent.standarizedHours.saturdayEnd.slice(0, 5)) : 'Closed';
        this.infoContent.standarizedHours.sundayStart = this.infoContent.standarizedHours.sundayStart ? this.tConvert(this.infoContent.standarizedHours.sundayStart.slice(0, 5)) : 'Closed';
        this.infoContent.standarizedHours.sundayEnd = this.infoContent.standarizedHours.sundayEnd ? this.tConvert(this.infoContent.standarizedHours.sundayEnd.slice(0, 5)) : 'Closed';

        if (this.infoContent.standarizedHours.mondayStart === 'Closed' || this.infoContent.standarizedHours.mondayEnd === 'Closed')
          this.infoContent.standarizedHours.mIsCLosed = true;
        if (this.infoContent.standarizedHours.tuesdayStart === 'Closed' || this.infoContent.standarizedHours.tuesdayEnd === 'Closed')
          this.infoContent.standarizedHours.tIsCLosed = true;
        if (this.infoContent.standarizedHours.wednesdayStart === 'Closed' || this.infoContent.standarizedHours.wednesdayEnd === 'Closed')
          this.infoContent.standarizedHours.wIsCLosed = true;
        if (this.infoContent.standarizedHours.thursdayStart === 'Closed' || this.infoContent.standarizedHours.thursdayEnd === 'Closed')
          this.infoContent.standarizedHours.thIsCLosed = true;
        if (this.infoContent.standarizedHours.fridayStart === 'Closed' || this.infoContent.standarizedHours.fridayEnd === 'Closed')
          this.infoContent.standarizedHours.fIsCLosed = true;
        if (this.infoContent.standarizedHours.saturdayStart === 'Closed' || this.infoContent.standarizedHours.saturdayEnd === 'Closed')
          this.infoContent.standarizedHours.sIsCLosed = true;
        if (this.infoContent.standarizedHours.sundayStart === 'Closed' || this.infoContent.standarizedHours.sundayEnd === 'Closed')
          this.infoContent.standarizedHours.suIsCLosed = true;

        //flag to manage hours is empty
        this.checkingClosedHours(this.infoContent);

      }
        //console.log(res);
        this.info.open(marker)

      
    });

    
  }

  private checkingClosedHours(child: ChildCareProvider) {
    //flag to manage hours is empty
    child.isEmptyAllDayHours = false;
    if (child.standarizedHours.mIsCLosed && child.standarizedHours.tIsCLosed
      && child.standarizedHours.wIsCLosed && child.standarizedHours.thIsCLosed
      && child.standarizedHours.fIsCLosed && child.standarizedHours.sIsCLosed
      && child.standarizedHours.suIsCLosed) {
      child.isEmptyAllDayHours = true;

    }
  }

  activeMap() {
    // Handle the checkbox that show/hide the map
    //this.mapActive = !this.mapActive;
    //localStorage.setItem("mapActive", this.mapActive.toString());
    this.filter = new FilteredRequest(1, 12);
    //fix rendering legend when show/hide the map
    if (this.mapActive)
    setTimeout(() => { this.displayLegend() }, 3000);
  }

  rePositionCenter(markers: MapMarker[]) {
    // Calculate new center depending on the markers position
    if (markers.length > 0) {
      const bounds = new google.maps.LatLngBounds();
      this.markers.forEach(item => {
        bounds.extend(item.position);
      });
      this.map.fitBounds(bounds);
      if (markers.length === 1) {
        this.map.zoom = 14;
      }
    }
    else {
      this.map.center = this.center;
    }
  }

  getStatusImage(childCare: ChildCareProvider) {
    // Return the open/closed image for the cards
    //if (childCare.isDataPublishable) {
    if (childCare.openStatusId == 1) return this.openImg;
    if (childCare.openStatusId == 2) return this.openEnrolling;
    //  else return this.closedImg;
    //}
    //else {
    //  return this.notAvailableImg;
    //}
  }

  getTooltip(childCare: ChildCareProvider) {
    
    if (childCare.openStatusId == 1) return 'Open';
    if (childCare.openStatusId == 2) return 'Closed but accepting applications';
    
  }

  getProviderTypeString(id: number): string {
    // Return the provider type text token to show on the card
    switch (id) {
      case 1:
        return "main.providerOption1";
        break;
      case 2:
        return "main.providerOption2";
        break;
      case 3:
        return "main.providerOption3";
        break;
      case 4:
        return "main.providerOption4";
        break;
      case 5:
        return "main.providerOption5";
        break;
    }
  }

  getAvailabilityImg(provider: ChildCareProvider) {
    if(!provider.isRegistered) 
       return 2;    
    else return 3;

  }
    
  closeSUTQAlert() {    
    sessionStorage.setItem('closed-sutq', 'true');
  }

  closeHeadAlert() {
    sessionStorage.setItem('closed-head', 'true');
  }

  closeFeedbackAlert() {
    sessionStorage.setItem('closed-feedback', 'true');
  }

  openLink(urlToOpen: string) {

    let url: string = '';
    if (!/^http[s]?:\/\//.test(urlToOpen)) {
      url += 'http://';
    }

    url += urlToOpen;
    window.open(url, '_blank');

  }

  hideLeged() {
    const legend = document.getElementById("legend") as HTMLElement;
    legend.classList.remove("show-legend");
    legend.classList.add("hide-legend");
  }

  viewLeged() {
    const legend = document.getElementById("legend") as HTMLElement;
    legend.classList.remove("hide-legend");
    legend.classList.add("show-legend");
  }

  viewHideLegend() {
    const x = document.getElementById("legend");
    if (x.style.display === "none") {
      x.style.display = "block";
    } else {
      x.style.display = "none";
    }

    //dinamyc title
    const y = document.getElementById("viewHide");
    if (y.innerHTML === "Hide Map Legend") {
      y.innerHTML = "View Map Legend";
    } else {
      y.innerHTML = "Hide Map Legend";
    }


  }

  onMapClick() {
    console.log('map clicked');
    this.info.close();
  }
  
}
