import { Component, OnInit } from '@angular/core';
import { AuthenticationService, User } from 'src/app/auth/authentication.service';
import { ProviderMessagesService } from 'src/app/service/provider-messages.service';
import { UserService } from 'src/app/service/user.service';

@Component({
  selector: 'app-provider-messages',
  templateUrl: './provider-messages.component.html',
  styleUrls: ['./provider-messages.component.css']
})
export class ProviderMessagesComponent implements OnInit {
  unreadMessages: any[];
  readMessages: any[];
  currentUser: User;

  constructor(
    private auth: AuthenticationService,
    private userService: UserService,
    private messagesService: ProviderMessagesService) { }

  ngOnInit(): void {


    this.auth.currentUser.subscribe((user: User) => {
      this.currentUser = user;
    });

    if (localStorage.getItem("isImpersonation")) 
      this.currentUser = JSON.parse(localStorage.getItem("currentUser"));

    this.getMessages();
  }
  markAsRead(message) {
    //console.log(message)
    this.messagesService.readMessage(message.id).subscribe(
      res => {
        console.log(res)
        this.getMessages();

      }
    )
  }
  getMessages() {
    this.messagesService.getActiveUnreadMessages(this.currentUser.id).subscribe(
      res => {
        this.unreadMessages = res;
      }
    );
    this.messagesService.getActiveReadMessages(this.currentUser.id).subscribe(
      res => {
        this.readMessages = res;
      }
    );
  }

}
