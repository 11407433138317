export class ProviderReminderSettings {
  providerId: number;
  emailEnabled: boolean;
  email: string;
  smsEnabled: boolean;
  cellPhoneNumber: string;
  frequencyId: number;
  lastUpdated: Date;
  isSubscribed: boolean;
  result: string;
  message: string;
}
