import { Component, OnInit } from '@angular/core';
import { RequestSupport } from '../../models/request-support';
import { CapacityRequestService } from '../../service/capacity-request.service';
import { ToastrService } from 'ngx-toastr';
import { AuthenticationService, User } from '../../auth/authentication.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {


  isProviderSelectedOption: boolean;
  selectedUserValue: number;
  selectedSubjectValue: number;
  activeLang = 'en';
  requestSuport: RequestSupport;
  currentUser: User;
  public isImpersonation = false;
  
  constructor(private childCareService: CapacityRequestService, private toastr: ToastrService, private auth: AuthenticationService) {
    this.requestSuport = new RequestSupport();
    this.requestSuport.subject = 0;
    this.requestSuport.userType = 0;
    if (localStorage.getItem("isImpersonation")) {
      this.isImpersonation = true;
      //this.auth.setImpersonation(true);
    }
    this.currentUser = new User();


  
  }

  ngOnInit() {
    //this.requestSuport = new RequestSupport();
    this.requestSuport.subject = 0;
    this.requestSuport.userType = 0;
    this.auth.currentUser.subscribe((user: User) => {
      this.currentUser = user;
      if (this.isImpersonation)
        this.currentUser = JSON.parse(localStorage.getItem("currentUser"));
    });
        
  }

  //ngOnDestroy() {
  //  this.requestSuport = new RequestSupport();
  //  this.requestSuport.subject = 0;
  //  this.requestSuport.userType = 0;
  //}

  openPolicy() {
    window.open("assets/files/policy.html");
  }

  addRequest() {    
    //this.requestSuport = new RequestSupport();
    this.requestSuport.subject = 0;
    this.requestSuport.userType = 0;
    this.requestSuport.name = '';
    this.requestSuport.email = '';
    this.requestSuport.phone = '';
    this.requestSuport.licenseNumber = '';
    this.requestSuport.message = '';
  }

  createRequestSupport() {

    this.childCareService.saveHelp(this.requestSuport).subscribe(result => {
      // this.childCare = result;

      if (result.result === "success") {

        if (this.activeLang === "en") {
          this.toastr.success("Your help request has been submitted");
        }
        else {
          this.toastr.success("Su solicitud de ayuda ha sido enviada");
        }

      }
      else {

        if (this.activeLang === "en") {
          this.toastr.error("An unexpected error occurred when submitting the request. Please try again.");
        }
        else {
          this.toastr.error("Se produjo un error inesperado al enviar la solicitud. Inténtalo nuevamente.");
        }

      }

      this.addRequest();
    });
  }
  
  userTypeSelected(event) {
    const selected = event;//.target.value;
    this.selectedUserValue = selected;
    if (selected === 1) {  // "Child Care Provider/Facility") 
      this.isProviderSelectedOption = true;
    } else {
      this.isProviderSelectedOption = false;
    }
    this.requestSuport.subject = 0;
  }

  selectedSubject(event) {
    const selected = event;
    this.selectedSubjectValue = selected;
  }

}
