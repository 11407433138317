import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { User } from "./authentication.service";

@Injectable({ providedIn: 'root' })
export class OAuthApiService {

  _baseUrl: string;

  constructor(private http: HttpClient, @Inject('BASE_URL') private baseUrl: string) {
    this._baseUrl = baseUrl;
   }

  public exchangeToken(request: OAuthTokenExchangeRequest) {
      const headers = new HttpHeaders(
        {
          'Content-Type':'application/json'
        }
      );

      return this.http.post<OAuthResponse>(`${this._baseUrl}oauth/exchangeToken`, request, { headers: headers});
  }
}

//TODO: Move to another file
export class OAuthTokenExchangeRequest {
  user: string;
  password:string;
  //grant_type: string;
}

export class OAuthResponse {
  accessToken: string;
  token_type: string;
  expires_in: number;
  refresh_token: string;
  user: User;
  message: string;
  result: string
}
